import React, { useEffect, useRef, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../../../services/api'
import { useField } from 'formik'

const contactFields = [
    {
        id: '',
        name: 'Selecione'
    },
    {
        id: 'contact_name',
        name: 'Contato - Nome',
    },
    {
        id: 'contact_number',
        name: 'Contato - Telefone',
    },
    {
        id: 'contact_email',
        name: 'Contato - Email',
    },
]

export function FieldSelect({
    value,
    onChange,
    label = 'Campos Customizados',
    ...props
}) {
    const [open, setOpen] = useState(false)
    const [smart, setSmart] = useState('')
    const [options, setOptions] = useState(contactFields)
    const [labelOptions, setLabelOptions] = useState('')
    const smartTimeout = useRef()
    const loading = open && options.length === 0

    useEffect(() => {
        const getLabel = async fieldId => {
            if (!fieldId) return

            const fromContact = contactFields.find(f => f.id === fieldId)
            if (fromContact) return fromContact

            return (await api.get(`/custom-field/${fieldId}`)).data
        }

        return getLabel(value).then(label => {
            setLabelOptions(label) 
        })
    }, [value])

    useEffect(() => {
        clearTimeout(smartTimeout.current)
        smartTimeout.current = setTimeout(() => {
            api.get('/custom-field', {
                filters: {
                    smart,
                },
            }).then(({ data }) => {
                const fromContact = contactFields.filter(
                    field =>
                        !smart ||
                        field.name?.toLowerCase().includes(smart?.toLowerCase()),
                )

                setOptions([...fromContact, ...data.data])
            })
        }, 500)
    }, [smart])

    const handleChange = (e, value) => {
        setLabelOptions(value)

        if (!value) return onChange(null)

        onChange(value.id)
    }

    return (
        <Autocomplete
            open={open}
            onOpen={() => setOpen(true)}
            onClose={() => setOpen(false)}
            getOptionSelected={(option, value) => option.id === value || option.id === value?.id}
            getOptionLabel={option => option?.name || ''}
            options={options}
            loading={loading}
            onChange={handleChange}
            value={labelOptions || ''}
            renderInput={params => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    onChange={e => setSmart(e.target.value)}
                    {...props}
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <>
                                {loading ? (
                                    <CircularProgress
                                        color="inherit"
                                        size={20}
                                    />
                                ) : null}
                                {params.InputProps.endAdornment}
                            </>
                        ),
                    }}
                />
            )}
        />
    )
}


export const FormikFieldSelect = props => {
    const [field, , helpers] = useField(props.name)

    const handleChange = value => {
        if (!props.onInputChange) helpers.setValue(value)
    }

    return (
        <FieldSelect
            {...field}
            {...props}
            onChange={handleChange}
        />
    )
}
