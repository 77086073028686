import React from 'react'

import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import Drawer from '@material-ui/core/Drawer'
import useAuth from 'hooks/useAuth.js'

const drawerWidth = 320

const useStyles = makeStyles(theme => ({
    drawer: {
        maxWidth: '100%',
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        position: 'absolute',
        maxWidth: '100%',
        width: drawerWidth,
        display: 'flex',
        borderTop: '1px solid rgba(0, 0, 0, 0.12)',
        borderRight: '1px solid rgba(0, 0, 0, 0.12)',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        borderRadius: '4px',
        // borderTopRightRadius: 4,
        // borderBottomRightRadius: 4,
    },
    header: {
        display: 'flex',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        backgroundColor: theme.palette.contactdrawer,
        alignItems: 'center',
        padding: theme.spacing(0, 1),
        minHeight: '73px',
        justifyContent: 'flex-start',
    },
    content: {
        display: 'flex',
        backgroundColor: theme.palette.contactdrawer,
        flexDirection: 'column',
        padding: '8px 0px 8px 8px',
        height: '100%',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const IframeDrawer = ({ open, drawerClose, iframe, contact, ticket }) => {
    const { user } = useAuth()
    const classes = useStyles()
    const url = iframe?.link
        ?.replaceAll('{ticketId}', ticket?.id)
        .replaceAll('{contactNumber}', contact?.number)
        .replaceAll('{contactEmail}', contact?.email)
        .replaceAll('{contactId}', contact?.id)
        .replaceAll('{ticketUserId}', ticket?.userId)
        .replaceAll('{userId}', user?.id)

    return (
        <Drawer
            open={open}
            className={open ? classes.drawer : null}
            style={{ width: open ? Number(iframe?.width): null }}
            variant="persistent"
            anchor="right"
            PaperProps={{
                style: { width: open ? Number(iframe?.width): null },
            }}
            BackdropProps={{ style: { position: 'absolute' } }}
            ModalProps={{
                container: document.getElementById('drawer-container'),
                style: { position: 'absolute' },
            }}
            classes={{
                paper: classes.drawerPaper,
            }}>
            <div className={classes.header}>
                <IconButton onClick={drawerClose}>
                    <CloseIcon />
                </IconButton>
                <Typography style={{ justifySelf: 'center' }}>
                    {iframe?.name}
                </Typography>
            </div>
            <div className={classes.content}>
                <iframe
                    style={{
                        width: '100%',
                        height: '100%',
                        border: 'none',
                    }}
                    src={url}
                    title={iframe?.name}
                />
            </div>
        </Drawer>
    )
}

export default IframeDrawer
