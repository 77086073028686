const { makeStyles, Grid } = require("@material-ui/core")
const { default: Title } = require(".")

const useStyles = makeStyles(theme => ({
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    buttons: {
        display: 'flex',
        gap: 5
    }
}))

const TitleWithButton = ({ gridProps = {}, children, title, ...props }) => {
    const classes = useStyles()

    return (
        <Grid item xs={12} className={classes.container} {...gridProps}>
            <Title>{title}</Title>
            <div className={classes.buttons}>{children}</div>
        </Grid>
    )
}

export default TitleWithButton
