import React, {
    useState,
    useEffect,
    useReducer,
    useCallback,
    useContext,
} from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import TagModal from '../../components/TagModal'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import { Chip, Grid } from '@material-ui/core'
import { SocketContext } from '../../context/Socket/SocketContext'
import { AuthContext } from '../../context/Auth/AuthContext'
import useCan from 'hooks/useCan'
import { AddCircleOutline } from '@material-ui/icons'

const reducer = (state, action) => {
    if (action.type === 'LOAD_TAGS') {
        const tags = action.payload
        const newTags = []

        tags.forEach(tag => {
            const tagIndex = state.findIndex(s => s.id === tag.id)
            if (tagIndex !== -1) {
                state[tagIndex] = tag
            } else {
                newTags.push(tag)
            }
        })

        return [...state, ...newTags]
    }

    if (action.type === 'UPDATE_TAGS') {
        const tag = action.payload
        const tagIndex = state.findIndex(s => s.id === tag.id)

        if (tagIndex !== -1) {
            state[tagIndex] = tag
            return [...state]
        } else {
            return [tag, ...state]
        }
    }

    if (action.type === 'DELETE_TAGS') {
        const tagId = action.payload

        const tagIndex = state.findIndex(s => s.id === tagId)
        if (tagIndex !== -1) {
            state.splice(tagIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const Tags = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const { user } = useContext(AuthContext)

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedTag, setSelectedTag] = useState(null)
    const [deletingTag, setDeletingTag] = useState(null)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [smart, setSmart] = useState('')
    const [tags, dispatch] = useReducer(reducer, [])
    const [tagModalOpen, setTagModalOpen] = useState(false)

    const fetchTags = useCallback(async () => {
        try {
            const { data } = await api.get('/tags/', {
                filters: { smart, countTags: 1 },
                include: { Tickets: 'id'},
                page: pageNumber,
            })
            dispatch({ type: 'LOAD_TAGS', payload: data.data })
            setHasMore(tags.length < data.total)
            setLoading(false)
        } catch (err) {
            toastError(err)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [smart, pageNumber])

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [smart])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            fetchTags()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [smart, pageNumber, fetchTags])

    useEffect(() => {
        const socket = socketManager.GetSocket(user.companyId)

        socket.on('tag', data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_TAGS', payload: data.tag })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_TAGS', payload: +data.tagId })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [user, socketManager])

    const handleOpenTagModal = () => {
        setSelectedTag(null)
        setTagModalOpen(true)
    }

    const handleCloseTagModal = () => {
        setSelectedTag(null)
        setTagModalOpen(false)
    }

    const handleSearch = event => {
        setSmart(event.target.value.toLowerCase())
    }

    const handleEditTag = tag => {
        setSelectedTag(tag)
        setTagModalOpen(true)
    }

    const handleDeleteTag = async tagId => {
        try {
            await api.delete(`/tags/${tagId}`)
            toast.success('Tag excluída com sucesso.')
        } catch (err) {
            toastError(err)
        }
        setDeletingTag(null)
        setSmart('')

        dispatch({ type: 'RESET' })
        setPageNumber(1)
        await fetchTags()
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 1) < clientHeight) {
            loadMore()
        }
    }

    if (!canOrReturn(['tag:page', 'tag:create', 'tag:update', 'tag:delete'], '/tickets'))
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingTag &&
                    `Você tem certeza que quer excluir esta Tag?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteTag(deletingTag.id)}>
                Esta ação não pode ser revertida!
            </ConfirmationModal>
            <TagModal
                open={tagModalOpen}
                onClose={handleCloseTagModal}
                reload={fetchTags}
                aria-labelledby="form-dialog-title"
                tagId={selectedTag && selectedTag.id}
            />
            <MainHeader>
                <Grid style={{ width: '99.6%' }} container>
                    <Grid xs={12} sm={6} md={8} item>
                        <Title>Tags</Title>
                    </Grid>
                    <Grid xs={12} sm={6} md={4} item>
                        <Grid spacing={2} container>
                            <Grid xs={6} item>
                                <TextField
                                    placeholder={'Pesquisar...'}
                                    type="search"
                                    value={smart}
                                    onChange={handleSearch}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon style={{ color: 'gray' }} />
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                            <Grid xs={6} item>
                                <Button
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    onClick={handleOpenTagModal}
                                >
                                    <Grid container justifyContent='center' alignItems="center" style={{ gap: 4 }}>
                                        <AddCircleOutline />
                                        Adicionar
                                    </Grid>
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                 Descrição de Uso
                            </TableCell>
                            <TableCell align="center">
                                Tickets com essa Tag
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {tags.map(tag => (
                                <TableRow key={tag.id}>
                                    <TableCell align="center" width="20%">
                                        <Chip
                                            variant="outlined"
                                            style={{
                                                backgroundColor: tag.color,
                                                textShadow: '1px 1px 1px #000',
                                                color: 'white',
                                            }}
                                            label={tag.name}
                                            size="small"
                                        />
                                    </TableCell>
                                    <TableCell align="center">
                                        {tag.description}
                                    </TableCell>
                                    <TableCell align="center" width="20%">
                                        {tag.tickets?.length}
                                    </TableCell>
                                    <TableCell align="center" width="10%">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleEditTag(tag)}>
                                            <EditIcon />
                                        </IconButton>

                                        <IconButton
                                            size="small"
                                            onClick={e => {
                                                setConfirmModalOpen(true)
                                                setDeletingTag(tag)
                                            }}>
                                            <DeleteOutlineIcon />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Tags
