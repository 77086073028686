import api from '../../services/api'

const useMessages = () => {

    const search = async (ticketId, options = {}) => {
        const { data: { total, data: messages }} = await api.get('/messages/' + ticketId, options)
        return { total, messages }
    }

    const list = async (messageId, options = {}) => {
        const { data: { total, data: messages }} = await api.get('/messages/list/' + messageId, options)
        return { total, messages }
    }

    return {
        list,
        search
    }
}

export default useMessages
