import React, { useState, useEffect, useReducer, useContext } from 'react'

import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Paper from '@material-ui/core/Paper'

import TicketListItem from '../TicketListItemCustom'
import TicketsListSkeleton from '../TicketsListSkeleton'

import useTickets from '../../hooks/useTickets'
import { AuthContext } from '../../context/Auth/AuthContext'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'
import collect from 'collect.js'

const useStyles = makeStyles(theme => ({
    ticketsListWrapper: {
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflow: 'hidden',
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
    },

    ticketsList: {
        flex: 1,
        maxHeight: '100%',
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
        borderTop: '2px solid rgba(0, 0, 0, 0.12)',
    },

    ticketsListHeader: {
        color: 'rgb(67, 83, 105)',
        zIndex: 2,
        backgroundColor: 'white',
        borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },

    ticketsCount: {
        fontWeight: 'normal',
        color: 'rgb(104, 121, 146)',
        marginLeft: '8px',
        fontSize: '14px',
    },

    noTicketsText: {
        textAlign: 'center',
        color: 'rgb(104, 121, 146)',
        fontSize: '14px',
        lineHeight: '1.4',
    },

    noTicketsTitle: {
        textAlign: 'center',
        fontSize: '16px',
        fontWeight: '600',
        margin: '0px',
    },

    noTicketsDiv: {
        display: 'flex',
        height: '100px',
        margin: 40,
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
    },

    listItem: {
        '& .MuiListItem-container:hover [class*="actionPinned"]': {
            display: 'block',
        },

        '& .MuiListItem-container .isPinned': {
            display: 'block',
        },
    },
}))

const sortTickets = (tickets, orderOlder) => {
    if (!orderOlder)
        return collect(tickets)
            .sortBy(item => `${item.isPinned}-${item.updatedAt}`)
            .all()
            .reverse()

    return collect(tickets)
        .sortByDesc(item => item.updatedAt)
        .sortBy(item => item.isPinned)
        .all()
        .reverse()
}

const reducer = (state, action) => {
    if (action.type === 'LOAD_TICKETS') {
        action.payload.forEach(ticket => {
            const ticketIndex = state.findIndex(t => t.id === ticket.id)
            if (ticketIndex !== -1) {
                state[ticketIndex] = { ...state[ticketIndex], ...ticket }
            } else {
                state.push(ticket)
            }
        })

        return [...state]
    }

    if (action.type === 'RESET_UNREAD') {
        const ticketId = action.payload

        const ticketIndex = state.findIndex(t => t.id === ticketId)
        if (ticketIndex !== -1) {
            state[ticketIndex].unreadMessages = 0
        }

        return [...state]
    }

    if (action.type === 'UPDATE_TICKET') {
        const ticket = action.payload

        const ticketIndex = state.findIndex(t => t.id === ticket.id)
        if (ticketIndex !== -1) {
            state[ticketIndex] = { ...state[ticketIndex], ...ticket }
        } else {
            state.unshift(ticket)
        }

        return [...state]
    }

    if (action.type === 'UPDATE_TICKET_UNREAD_MESSAGES') {
        const ticket = action.payload

        const ticketIndex = state.findIndex(t => t.id === ticket.id)
        if (ticketIndex !== -1) {
            state[ticketIndex] = { ...state[ticketIndex], ...ticket }
            state.unshift(state.splice(ticketIndex, 1)[0])
        } else {
            state.unshift(ticket)
        }

        return [...state]
    }

    if (action.type === 'UPDATE_TICKET_CONTACT') {
        const contact = action.payload
        const ticketIndex = state.findIndex(t => t.contactId === contact.id)
        if (ticketIndex !== -1) {
            state[ticketIndex].contact = {
                ...state[ticketIndex].contact,
                ...contact,
            }
        }
        return [...state]
    }

    if (action.type === 'DELETE_TICKET') {
        const ticketId = action.payload
        const ticketIndex = state.findIndex(t => t.id === ticketId)
        if (ticketIndex !== -1) {
            state.splice(ticketIndex, 1)
        }

        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const TicketsListCustom = props => {
    const {
        status,
        searchParam,
        tags,
        users,
        connections,
        isGroup,
        selectedQueueIds,
        updateCount,
        style,
        orderOlder,
        messagesBetween,
        unansweredMessages
    } = props

    const { can } = useCan()
    const socketManager = useContext(SocketContext)
    const classes = useStyles()
    const [pageNumber, setPageNumber] = useState(1)
    const [ticketsList, dispatch] = useReducer(reducer, [])
    const { user } = useContext(AuthContext)
    const { queues } = user

    const ordened = sortTickets(ticketsList, orderOlder)

    useEffect(() => {
        // Limpando a lista de tickets caso seja alterado algum filtro
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [status, searchParam, orderOlder, tags, users, connections, selectedQueueIds, messagesBetween, unansweredMessages])

    const { tickets, total, loading } = useTickets({
        pageNumber,
        searchParam,
        status,
        sort: orderOlder ? 'updatedAt' : '-updatedAt',
        tags: tags,
        users: users,
        connections: connections,
        queueIds: selectedQueueIds,
        isGroup,
        messagesBetween: messagesBetween,
        unansweredMessages: unansweredMessages
    })

    useEffect(() => {
        dispatch({ type: 'LOAD_TICKETS', payload: tickets })
    }, [tickets])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        const shouldUpdateTicket = ticket =>
            !searchParam &&
            (!users?.length || users.includes(ticket.userId)) &&
            (selectedQueueIds.indexOf(ticket.queueId) > -1 ||
                (can('ticket:see:all:department') &&
                    !selectedQueueIds.length) ||
                (!selectedQueueIds.length &&
                    user.queues.length &&
                    user.queues.some(q => q.id === ticket.queueId))) &&
            isGroup === ticket.isGroup &&
            (!status ||
                status === ticket.status ||
                (Array.isArray(status) && status.includes(ticket.status)))

        const notBelongsToUser = ticket =>
            ((!can('ticket:see:all:department') && !user.queues.some(q => q.id === ticket.queueId)) &&
            (!can('ticket:see:without:department') && !ticket.queueId)) ||
            (ticket.userId && !can('ticket:see:other:user') && user.id !== ticket.userId) ||
            selectedQueueIds.indexOf(ticket.queueId) === -1

        socketManager.onConnect(() => {
            if (status) {
                socket.emit('joinTickets', status)
            } else {
                socket.emit('joinNotification')
            }
        })

        socket.on(`company-${companyId}-ticket`, data => {
            if (data.action === 'updateUnread') {
                dispatch({
                    type: 'RESET_UNREAD',
                    payload: data.ticketId,
                })
            }

            if (data.action === 'update') {
                if (notBelongsToUser(data.ticket))
                    dispatch({ type: 'DELETE_TICKET', payload: data.ticket.id })
                else if (shouldUpdateTicket(data.ticket))
                    dispatch({ type: 'UPDATE_TICKET', payload: data.ticket })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_TICKET', payload: data.ticketId })
            }
        })

        socket.on(`company-${companyId}-appMessage`, data => {
            const queueIds = queues?.map(q => q.id)

            if (
                data.ticket.userId &&
                data.ticket.userId !== user.id &&
                ((!can('ticket:see:all:department') &&
                    queueIds.indexOf(data.ticket?.queue?.id) === -1) ||
                    (!can('ticket:see:without:department') &&
                        data.ticket.queue === null) ||
                    !can('ticket:see:other:user'))
            ) {
                // Se o ticket não tem setor e o usuário não pode ver tickets sem setor
                // Se o ticket tiver setor mas o usuário não fizer parte desse setor e não tiver permissão de ver todos os setores
                // Se o ticket for de outro usuário e o usuário não tiver permissão de ver tickets de outros usuários
                return
            }

            if (
                data.action === 'create' &&
                shouldUpdateTicket(data.ticket) &&
                (!status ||
                    data.ticket.status === status ||
                    status.includes(data.ticket.status))
            ) {
                dispatch({
                    type: 'UPDATE_TICKET_UNREAD_MESSAGES',
                    payload: data.ticket,
                })
            }
        })

        socket.on(`company-${companyId}-contact`, data => {
            if (data.action === 'update') {
                dispatch({
                    type: 'UPDATE_TICKET_CONTACT',
                    payload: data.contact,
                })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [
        status,
        user,
        selectedQueueIds,
        tags,
        users,
        isGroup,
        can,
        queues,
        socketManager,
        searchParam,
    ])

    useEffect(() => {
        if (typeof updateCount === 'function') {
            updateCount(ticketsList.length)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [ticketsList])

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (total <= ticketsList?.length || loading) return

        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget

        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    return (
        <Paper className={classes.ticketsListWrapper} style={style}>
            <Paper
                square
                name="closed"
                elevation={0}
                className={classes.ticketsList}
                onScroll={handleScroll}>
                <List style={{ paddingTop: 0 }} className={classes.listItem}>
                    {ticketsList.length === 0 && !loading ? (
                        <div className={classes.noTicketsDiv}>
                            <span className={classes.noTicketsTitle}>
                                Nada aqui!
                            </span>
                            <p className={classes.noTicketsText}>
                                Nenhum atendimento encontrado com esse status ou
                                termo pesquisado
                            </p>
                        </div>
                    ) : (
                        <>
                            {ordened.map(ticket => (
                                <TicketListItem
                                    dispatch={dispatch}
                                    ticket={ticket}
                                    key={ticket.id}
                                    search={searchParam}
                                />
                            ))}
                        </>
                    )}
                    {loading && <TicketsListSkeleton />}
                </List>
            </Paper>
        </Paper>
    )
}

export default TicketsListCustom
