import { Button, Dialog, DialogActions, DialogTitle, Tooltip } from '@material-ui/core'
import QrcodeModal from 'components/QrcodeModal'
import toastError from 'errors/toastError'
import React from 'react'
import api from 'services/api'


const SessionConnectModal = ({ open, onClose, whatsAppId }) => {

    const [confirmationOpen, setConfirmationOpen] = React.useState(false)
    const [qrCodeOpen, setQrCodeOpen] = React.useState(false)

    React.useEffect(() => {
        !open && setQrCodeOpen(false)
        setConfirmationOpen(open)
    }, [open])

    if (!open)
        return null

    const handleImportResponse = async (importChats) => {
        setConfirmationOpen(false)
        try {
            await api.put(`/whatsapp/${whatsAppId}`, { importChats })
        } catch (err) {
            toastError(err)
            return
        }

        setQrCodeOpen(true)
    }

    return (
        <>
            <Dialog
                open={confirmationOpen}
                onClose={onClose}
                maxWidth="md"
            >
                <DialogTitle>
                    Deseja importar as conversas do WhatsApp?
                </DialogTitle>
                <DialogActions>
                    <Tooltip
                        title="Importa as mensagens de contatos que possuem conversas iniciadas em seu WhatsApp."
                        placement="top"
                        arrow
                    >
                        <Button
                            onClick={() => handleImportResponse(true)}
                            color="primary"
                            autoFocus
                        >
                            Importar
                        </Button>
                    </Tooltip>
                    <Button onClick={() => handleImportResponse(false)} color="secondary">
                        Não importar
                    </Button>
                </DialogActions>
            </Dialog>
            <QrcodeModal
                open={qrCodeOpen}
                onClose={onClose}
                whatsAppId={whatsAppId}
            />
        </>
    )
}

export default SessionConnectModal