import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import { Grid } from '@material-ui/core';
import MomentUtils from '@date-io/moment'

export function DateTimeBetweenFilter({ onChange, value }) {

    const handleChangeStart = (date) => {
        if (date && date.isValid()) {
            onChange({ ...value, start: date.format() });
        } else {
            onChange({...value, start: null})
        }
    }

    const handleChangeEnd = (date) => {
        if (date && date.isValid()) {
            onChange({ ...value, end: date.format() });
        } else {
            onChange({...value, end: null})
        }
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid style={{ padding: '0px 10px 10px' }} container spacing={1}>
                <Grid item xs={6}>
                    <KeyboardDateTimePicker
                        ampm={false}
                        label='De'
                        size='small'
                        fullWidth
                        inputVariant="outlined"
                        format="DD/MM/yyyy HH:mm"
                        name="startDate"
                        value={value.start}
                        clearable 
                        onChange={handleChangeStart}
                    />
                </Grid>
                <Grid item xs={6}>
                    <KeyboardDateTimePicker
                        ampm={false}
                        label='Até'
                        size='small'
                        fullWidth
                        inputVariant="outlined"
                        format="DD/MM/yyyy HH:mm"
                        name="endDate"
                        value={value.end}
                        clearable 
                        onChange={handleChangeEnd}
                    />
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}
