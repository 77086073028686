import api from "../../services/api";

const useSettings = () => {

    const getSetting = async (params) => {
        if (params)
            params = { key: params }

        const { data } = await api.get('/settings', params)

        return data
    }

    const setSetting = async (key, value) => {
        if (value !== undefined)
            return await api.put(`/settings/${key}`, { value: value }).data

        return (await api.put(`/settings`, { key }))
    }

    return {
        getSetting,
        setSetting,
    }
}

export default useSettings;
