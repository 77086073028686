import {
    FormControl,
    FormHelperText,
    InputLabel,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import { AuthContext } from 'context/Auth/AuthContext'
import { useContext, useEffect, useState } from 'react'
import api from 'services/api'

const ConnectionSelect = ({ value, onChange, error, label, defaultText = 'Selecione uma Conexão', ...props }) => {
    const [whatsapps, setWhatsapps] = useState([])
    const { user } = useContext(AuthContext)
    const { companyId, whatsappId } = user

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsappId !== null && whatsappId !== undefined)
                onChange(whatsappId)

            api.get(`/whatsapp`, { companyId, session: 0 }).then(({ data }) =>
                setWhatsapps(data),
            )
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [companyId, user, whatsappId, onChange])

    return (
        <FormControl fullWidth variant="outlined" error={Boolean(error)} {...props}>
            {label && (
                <InputLabel shrink={value ? true : false}>{label}</InputLabel>
            )}

            <Select
                displayEmpty
                value={value || ''}
                onChange={e => {
                    onChange(e.target.value)
                }}
                MenuProps={{
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    getContentAnchorEl: null,
                }}
                renderValue={() => {
                    if (value === '' || !value) return defaultText

                    return whatsapps.find(w => w.id === value)?.name
                }}>
                <MenuItem value={null}>Nenhum</MenuItem>

                {whatsapps?.length > 0 &&
                    whatsapps.map((whatsapp, key) => (
                        <MenuItem dense key={key} value={whatsapp.id}>
                            <ListItemText
                                primary={
                                    <Typography
                                        component="span"
                                        style={{ fontSize: 14 }}>
                                        {whatsapp.name} ({whatsapp.status})
                                    </Typography>
                                }
                            />
                        </MenuItem>
                    ))}
            </Select>
            {Boolean(error) && <FormHelperText>{error}</FormHelperText>}
        </FormControl>
    )
}

export default ConnectionSelect
