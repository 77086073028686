import React, { useEffect } from "react";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";

import ModalImage from "react-modal-image";

const useStyles = makeStyles(theme => ({
	messageMedia: {
		objectFit: "cover",
		width: 250,
		height: 200,
		borderRadius: 8,
	},
    sticker: {
        width: 128,
        height: 128
    }
}));

const ModalImageCors = ({ isSticker, imageUrl, hideDownload }) => {
	const classes = useStyles();

	useEffect(() => {
        //Feito para não executar o evento do Escape para fechar a conversa quando o modal está maximizado
		const handleKeyDown = (event) => {
			if (event.key === "Escape" && document.querySelector("#react-modal-image-img")) {
				event.stopPropagation();
			}
		};

		document.addEventListener("keydown", handleKeyDown);
		return () => {
			document.removeEventListener("keydown", handleKeyDown);
		};
	}, []);

	return (
		<ModalImage
            hideDownload={hideDownload}
			className={clsx([
                classes.messageMedia,
                {
                    sticker: isSticker,
                    [classes.sticker]: isSticker,
                }
            ])}
			smallSrcSet={imageUrl}
			medium={imageUrl}
			large={imageUrl}
			alt="image"
		/>
	);
};

export default ModalImageCors;
