import { useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'

const useTabs = (defaultValue, urlParam = 'tab') => {
    const [tab, setTab] = useState(defaultValue)

    const history = useHistory()

    const searchParams = useMemo(
        () => new URLSearchParams(history.location.search),
        [history.location.search],
    )

    // Captura no primeiro LOAD a aba da URL e seta
    useEffect(() => {
        const tab = searchParams.get(urlParam)

        if (tab) setTab(tab)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // Atualiza na URL quando a aba é alterada
    useEffect(() => {
        searchParams.set(urlParam, tab)
        history.replace(history.location.pathname + '?' + searchParams)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tab])

    return {
        tab,
        setTab,
    }
}

export default useTabs
