import api from '../../services/api'

const useContacts = () => {
    const search = async (contactId, options = {}) => {
        const {
            data: { total, data: contacts },
        } = await api.get('/contacts/' + contactId, options)
        return { total, contacts }
    }

    const list = async (options = {}) => {
        const {
            data: { total, data: contacts },
        } = await api.get('/contacts', options)
        return { total, contacts }
    }

    return {
        list,
        search,
    }
}

export default useContacts
