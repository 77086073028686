import MomentUtils from '@date-io/moment'
import {
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@material-ui/core'
import { Delete } from '@material-ui/icons'
import {
    KeyboardDateTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import { v4 } from 'uuid'

const Operator = ({ value, onChange, type, ...props }) => {
    const operatorsToShow = operators.filter(operator =>
        operator.types.includes(type),
    )

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel shrink={true}>Operador</InputLabel>
            <Select
                fullWidth
                label="Operador"
                value={value}
                variant="outlined"
                defaultValue="equal"
                onChange={onChange}>
                {operatorsToShow.map(operator => (
                    <MenuItem value={operator.value}>{operator.label}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const FilterField = ({
    type,
    name,
    filter,
    value,
    onChange,
    disabled,
    ...props
}) => {
    const componentId = v4()

    let Field = (
        <TextField
            fullWidth
            label={name}
            value={value}
            onChange={onChange}
            disabled={disabled}
        />
    )

    if (type === 'date') {
        Field = (
            <KeyboardDateTimePicker
                disabled={disabled}
                ampm={false}
                inputVariant="outlined"
                fullWidth
                format="DD/MM/yyyy HH:mm"
                label={name}
                value={value}
                onChange={e => onChange(e?.format('YYYY-MM-DD HH:mm:ss'))}
                InputLabelProps={{ shrink: true }}
            />
        )
    } else if (type === 'boolean') {
        Field = (
            <FormControl fullWidth variant="outlined" disabled={disabled}>
                <InputLabel id={`filter-${name}-${componentId}`}>
                    {name}
                </InputLabel>
                <Select
                    label={name}
                    value={value}
                    labelId={`filter-${name}-${componentId}`}
                    onChange={onChange}>
                    <MenuItem value="true">Sim</MenuItem>
                    <MenuItem value="false">Não</MenuItem>
                </Select>
            </FormControl>
        )
    }

    return Field
}

const TransmissionListFilter = ({ field, filter, onChange, onRemove }) => {
    const { name, type } = field

    const handleChange = (field, event) => {
        let value
        if (event && typeof event == 'object' && 'target' in event)
            value = event.target.value
        else value = event

        if (field === 'operator' && ['empty', 'not_empty'].includes(value))
            handleChange('value', '')

        filter[field] = value
        onChange(filter)
    }

    return (
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <Operator
                        fullWidth
                        type={type}
                        value={filter.operator}
                        onChange={e => handleChange('operator', e)}
                    />
                </Grid>
                <Grid item xs={8}>
                    <FilterField
                        disabled={['empty', 'not_empty'].includes(
                            filter.operator,
                        )}
                        value={filter.value}
                        filter={filter}
                        type={type}
                        name={name}
                        onChange={e => handleChange('value', e)}
                    />
                </Grid>
                <Grid item xs={1}>
                    <IconButton onClick={onRemove}>
                        <Delete color="error" />
                    </IconButton>
                </Grid>
            </Grid>
        </MuiPickersUtilsProvider>
    )
}

const operators = [
    {
        value: 'equal',
        label: 'Igual',
        types: ['text', 'multiple', 'boolean', 'date'],
    },
    {
        value: 'not_equal',
        label: 'Diferente',
        types: ['text', 'multiple', 'boolean', 'date'],
    },
    {
        value: 'contains',
        label: 'Contém',
        types: ['text', 'multiple'],
    },
    {
        value: 'not_contains',
        label: 'Não contém',
        types: ['text', 'multiple'],
    },
    {
        value: 'empty',
        label: 'Vazio',
        types: ['text', 'multiple'],
    },
    {
        value: 'not_empty',
        label: 'Não vazio',
        types: ['text', 'multiple'],
    },
    {
        value: 'starts',
        label: 'Inicia com',
        types: ['text'],
    },
    {
        value: 'not_starts',
        label: 'Não inicia com',
        types: ['text'],
    },
    {
        value: 'ends',
        label: 'Finaliza com',
        types: ['text'],
    },
    {
        value: 'not_ends',
        label: 'Não finaliza com',
        types: ['text'],
    },
    // COMENTADO POIS O BACKEND AINDA NÃO ENTENDE E PRECISA SER MELHORADO O SELETOR NO FRONT PARA TER DOIS CAMPOS MIN MAX
    // {
    //     value: 'between',
    //     label: 'Entre',
    //     types: ['date'],
    // },
    {
        value: 'greater_equal',
        label: 'Maior ou igual',
        types: ['date'],
    },
    {
        value: 'less_equal',
        label: 'Menor ou igual',
        types: ['date'],
    },
]

export default TransmissionListFilter
