import { Button, ButtonGroup, makeStyles, TextField } from "@material-ui/core"
import { Field } from "formik";

const useStyles = makeStyles((theme) => ({
    header: {
        gap:4,
        width: '100%',
        backgroundColor: theme.palette.background.main,
        minHeight: 40,
        paddingBlock: 10,
        paddingInline: 15,
        display: 'flex',
    }
}))

const ChatHeader = ({ saveFlow, toList, ...props }) => {
    const classes = useStyles();

    return (
        <div className={classes.header}>
            <Field
                as={TextField}
                name="name"
                label="Nome"
                variant="outlined"
                margin="dense"
                {...props.getFieldProps('name')}
                required
                error={props.touched.name && props.errors.name}
                helperText={props.touched.name && props.errors.name}
            />

            <ButtonGroup
                style={{ marginTop: 8, marginBottom: 4, marginLeft: 'auto' }}
                variant="contained"
                color="primary">
                <Button onClick={toList}>Voltar</Button>
                <Button onClick={saveFlow}>Salvar</Button>
            </ButtonGroup>
        </div>
    )
}

export default ChatHeader;
