import { useCallback, useEffect, useState, useContext } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import BackdropLoading from 'components/BackdropLoading'
import api from 'services/api'
import { useTheme } from '@material-ui/styles'
import toastError from 'errors/toastError'
import ConfirmationModal from 'components/ConfirmationModal'
import useCan from 'hooks/useCan'
import { AuthContext } from '../../context/Auth/AuthContext'

const OpenTicket = (...props) => {
    const theme = useTheme()
    const history = useHistory()
    const params = useParams()
    const { user } = useContext(AuthContext)
    const [loadingPage, setLoadingPage] = useState(true)
    const [ticket, setTicket] = useState()
    const [showAssumeModal, setShowAssumeModal] = useState(false)
    const { can } = useCan()

    const assumeTicket = useCallback(
        assume => {
            const parms = new URLSearchParams(window.location.search)

            api.post(`tickets/talk`, {
                number: params.number,
                whatsappId: parms.get('whatsappId'),
                assume,
            })
                .then(r => {
                    if (r.data.userId === user.id || can('admin'))
                        // Se for de sua responsabilidade ou admin redireciona para o ticket
                        history.push(`/tickets/${r.data.uuid}`)

                    if (r.data.userId && r.data.userId !== user.id)
                        setShowAssumeModal(true)

                    setTicket(r.data)
                    setLoadingPage(false)
                })
                .catch(err => {
                    toastError(err)
                    setLoadingPage(false)
                })
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [params.number],
    )

    useEffect(() => {
        assumeTicket()
    }, [params, assumeTicket])

    if (loadingPage)
        return (
            <BackdropLoading text="Buscando Ticket. Aguarde alguns instantes!" />
        )

    return (
        <div style={{ height: `calc(100% - ${theme.appBarHeight})` }}>
            <ConfirmationModal
                title="Assumir atendimento"
                open={showAssumeModal}
                onClose={() => history.push(`/tickets`)}
                onConfirm={() => assumeTicket(true)}>
                Este contato está em atendimento pelo usuário {ticket?.user?.name}. Você deseja assumir o atendimento?
            </ConfirmationModal>
        </div>
    )
}

export default OpenTicket
