import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    ListItemText,
    makeStyles,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { AuthContext } from '../../context/Auth/AuthContext'
import api from '../../services/api'
import ButtonWithSpinner from '../ButtonWithSpinner'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
const vCard = require('vcf')

const useStyles = makeStyles(theme => ({
    online: {
        fontSize: 11,
        color: '#25d366',
    },
    offline: {
        fontSize: 11,
        color: '#e1306c',
    },
}))

const TalkTo = ({ contact, modalOpen, onClose }) => {
    const router = useHistory()
    const classes = useStyles()
    const { user } = useContext(AuthContext)
    const { companyId, whatsappId } = user

    const [whatsapps, setWhatsapps] = useState([])
    const [selectedWhatsapp, setSelectedWhatsapp] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchWhatsapps = async () => {
                api.get(`/whatsapp`, {
                    companyId,
                    session: 0,
                }).then(({ data }) => setWhatsapps(data))
            }

            if (whatsappId) setSelectedWhatsapp(whatsappId)

            fetchWhatsapps()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [companyId, user.queues, whatsappId])

    const handleSaveTicket = async () => {
        const whatsappId = selectedWhatsapp !== '' ? selectedWhatsapp : null
        router.push(`/chat/${contact.number}?whatsappId=${whatsappId}`)
    }

    return (
        <Dialog open={modalOpen} onClose={onClose}>
            <DialogTitle id="form-dialog-title">
                Criar Ticket
            </DialogTitle>
            <DialogContent dividers>
                <Grid style={{ width: 300 }} container spacing={2}>
                    <Grid xs={12} item>
                        <Select
                            required
                            fullWidth
                            displayEmpty
                            variant="outlined"
                            value={selectedWhatsapp}
                            onChange={e => {
                                setSelectedWhatsapp(e.target.value)
                            }}
                            MenuProps={{
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                },
                                transformOrigin: {
                                    vertical: 'top',
                                    horizontal: 'left',
                                },
                                getContentAnchorEl: null,
                            }}
                            renderValue={() => {
                                if (selectedWhatsapp === '')
                                    return 'Selecione uma Conexão'
                                const whatsapp = whatsapps.find(
                                    w => w.id === selectedWhatsapp,
                                )
                                return whatsapp.name
                            }}>
                            {whatsapps?.length > 0 &&
                                whatsapps.map((whatsapp, key) => (
                                    <MenuItem
                                        dense
                                        key={key}
                                        value={whatsapp.id}>
                                        <ListItemText
                                            primary={
                                                <>
                                                    <Typography
                                                        component="span"
                                                        style={{
                                                            fontSize: 14,
                                                            marginLeft: '10px',
                                                            display:
                                                                'inline-flex',
                                                            alignItems:
                                                                'center',
                                                            lineHeight: '2',
                                                        }}>
                                                        {whatsapp.name} &nbsp;{' '}
                                                        <p
                                                            className={
                                                                whatsapp.status ===
                                                                    'CONNECTED'
                                                                    ? classes.online
                                                                    : classes.offline
                                                            }>
                                                            ({whatsapp.status})
                                                        </p>
                                                    </Typography>
                                                </>
                                            }
                                        />
                                    </MenuItem>
                                ))}
                        </Select>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="secondary" variant="outlined">
                    Cancelar
                </Button>
                <ButtonWithSpinner
                    variant="contained"
                    type="button"
                    onClick={handleSaveTicket}
                    color="primary">
                    Salvar
                </ButtonWithSpinner>
            </DialogActions>
        </Dialog>
    )
}

const SingleVcard = ({ contact, handleContact }) => {
    return (
        <>
            <div>{contact.name}</div>
            <small>{contact.number}</small>
            <Button
                fullWidth
                color="primary"
                onClick={() => handleContact(contact)}>
                💬 Conversar
            </Button>
        </>
    )
}

const VcardPreview = ({ vcard }) => {
    const [contactOpened, setContactOpened] = useState()

    const cards = vCard.parse(vcard.replaceAll('\n', '\r\n'))

    const contacts = cards.map(card => ({
        name: card.data.fn._data,
        number: card.data.tel.waid,
    }))

    return (
        <>
            <div
                style={{
                    minWidth: '250px',
                }}>
                {contacts.map(contact => (
                    <SingleVcard
                        contact={contact}
                        key={contact.number}
                        handleContact={setContactOpened}
                    />
                ))}
                <TalkTo
                    contact={contactOpened}
                    modalOpen={!!contactOpened}
                    onClose={() => setContactOpened(null)}
                />
            </div>
        </>
    )
}

export default VcardPreview
