import React from 'react'

import { isSameDay, parseISO, format } from 'date-fns'
import useStyles from '../useStyles'

export default function DailyTimestamp({ currentMessage, previousMessage }) {
    const classes = useStyles()

    if (previousMessage) {
        const messageDay = parseISO(currentMessage.createdAt)
        const previousMessageDay = parseISO(previousMessage.createdAt)

        if (isSameDay(messageDay, previousMessageDay)) return null
    }

    return (
        <span
            className={classes.dailyTimestamp}
            key={`timestamp-${currentMessage.id}`}>
            <div className={classes.dailyTimestampText}>
                {format(parseISO(currentMessage.createdAt), 'dd/MM/yyyy')}
            </div>
        </span>
    )
}
