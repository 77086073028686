import { useEffect, useState } from 'react'
import api from 'services/api'
import {
    DialogContent,
    DialogActions,
    Dialog,
    DialogTitle,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Grid,
    Button,
} from '@material-ui/core'
import { toast } from 'react-toastify'
import TransmissionListFilter from './TransmissionListFilter'
import useContacts from '../../hooks/useContacts'

const SelectField = ({ onChange, options, ...props }) => {
    const handleChange = e => {
        onChange(e)
        e.target.value = null
    }

    return (
        <FormControl fullWidth variant="outlined">
            <InputLabel shrink={false}>Campo</InputLabel>
            <Select
                fullWidth
                variant="outlined"
                defaultValue={null}
                value={''}
                onChange={handleChange}>
                {options.map(operator => (
                    <MenuItem value={operator.id}>{operator.name}</MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const TransmissionListAdd = ({ open, setList, onClose, ...props }) => {
    const { list } = useContacts()

    const [fields, setFields] = useState([])
    const [filters, setFilters] = useState([])

    useEffect(() => {
        if (open) getFields().then(f => setFields(f))
        else setFilters([])
    }, [open])

    const addFilter = e => {
        setFilters(prev => [
            ...prev,
            { fieldId: e.target.value, value: '', operator: 'equal' },
        ])
    }

    const findField = fieldId => {
        return fields.find(f => f.id === fieldId)
    }

    const filterChange = (index, filter) => {
        setFilters(prev => {
            prev[index] = filter
            return [...prev]
        })
    }

    const filterRemove = index => {
        setFilters(prev => {
            prev.splice(index, 1)
            return [...prev]
        })
    }

    const handleAdd = async () => {
        const canBeNull = ['empty', 'not_empty']

        if (!Boolean(filters?.length))
            return toast.error('Nenhum filtro foi informado!')

        if (
            filters.some(
                f =>
                    !canBeNull.includes(f.operator) &&
                    [null, undefined, ''].includes(f.value),
            )
        )
            return toast.error(
                'Todos os filtros devem possuir valores informados!',
            )

        const newFilters = { customFields: [] }
        filters.forEach(filter => {
            if (Number.isInteger(filter.fieldId)) {
                newFilters.customFields.push({ ...filter })
            } else {
                newFilters[`${filter.fieldId}|${filter.operator}`] =
                    filter.value
            }
        })

        const { contacts } = await list({
            filters: newFilters,
        })

        setList(contacts.map(contact => ({ contactId: contact.id, contact })))
        onClose()
    }

    return (
        <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
            <DialogTitle>Adicionar contatos à lista</DialogTitle>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={6}>
                        <SelectField options={fields} onChange={addFilter} />
                    </Grid>
                </Grid>

                {Boolean(filters?.length) &&
                    filters?.map((filter, filterIdx) => (
                        <TransmissionListFilter
                            key={filterIdx}
                            field={findField(filter.fieldId)}
                            filter={filter}
                            onChange={filter => filterChange(filterIdx, filter)}
                            onRemove={() => filterRemove(filterIdx)}
                        />
                    ))}
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={onClose}>
                    Cancelar
                </Button>
                <Button color="primary" onClick={handleAdd}>
                    Adicionar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const getFields = async () => {
    const customFields = (await getAllCustomFields()).map(cf => ({
        id: cf.id,
        name: cf.name,
        type: 'text',
    }))

    return [
        { id: 'name', name: 'Contato - Nome', type: 'text' },
        { id: 'number', name: 'Contato - Telefone', type: 'text' },
        { id: 'email', name: 'Contato - E-mail', type: 'text' },
        { id: 'isGroup', name: 'Contato - É grupo', type: 'boolean' },
        { id: 'createdAt', name: 'Contato - Data de cadastro', type: 'date' },
        {
            id: 'updatedAt',
            name: 'Contato - Data de Atualização',
            type: 'date',
        },
        ...customFields,
    ]
}

const getAllCustomFields = async () => {
    return new Promise(async (resolve, reject) => {
        let page = 0
        let total = 0
        const items = []

        try {
            do {
                const response = (
                    await api.get('/custom-field', {
                        page: ++page,
                    })
                ).data

                total = response.total
                items.push(...response.data)
            } while (items.length < total)

            return resolve(items)
        } catch (err) {
            reject(err)
        }
    })
}

export default TransmissionListAdd
