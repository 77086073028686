import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import FilePicker, { ACCEPTS_IMAGE } from '../../components/FilePicker'
import useAuth from '../../hooks/useAuth.js'
import useCompanies from '../../hooks/useCompanies'
import useImages from '../../hooks/useImages'
import useMediaUpload from '../../hooks/useMediaUpload'

const ImageCard = ({
    companyId,
    relaction,
    description,
    update,
    dark,
    image,
    setImage,
}) => {
    const handleAttachmentFile = async file => {
        const updated = await update({
            id: companyId,
            [relaction]: file ?? null,
        })
        setImage(prev => ({ ...prev, [relaction]: updated[relaction] }))
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Typography
                    align="center"
                    variant="h6"
                    style={{ borderTop: '1px solid', marginTop: 8 }}>
                    {description}
                </Typography>
            </Grid>

            <Grid item xs={12}>
                <FilePicker
                    fullWidth
                    form={relaction}
                    reverseTheme={dark ? 'dark' : 'light'}
                    initial={{ url: image }}
                    accepts={ACCEPTS_IMAGE}
                    onFileChange={handleAttachmentFile}
                />
            </Grid>
        </Grid>
    )
}

const CompanyImages = () => {
    const { user } = useAuth()
    const { update } = useCompanies()
    const { uploadFile } = useMediaUpload()
    const {
        logo,
        logoDark,
        whatsappBackgroundLight,
        whatsappBackgroundDark,
        setImages,
    } = useImages()

    const companyId = user.companyId

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    relaction="logo"
                    description="Logo"
                    image={logo}
                    setImage={setImages}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    relaction="logoDark"
                    description="Logo (Dark)"
                    dark={true}
                    image={logoDark}
                    setImage={setImages}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    relaction="whatsappBackgroundLight"
                    description="Background"
                    image={whatsappBackgroundLight}
                    setImage={setImages}
                />
            </Grid>

            <Grid item xs={12} sm={6} md={3}>
                <ImageCard
                    companyId={companyId}
                    update={update}
                    uploadFile={uploadFile}
                    dark={true}
                    relaction="whatsappBackgroundDark"
                    description="Background (Dark)"
                    image={whatsappBackgroundDark}
                    setImage={setImages}
                />
            </Grid>
        </Grid>
    )
}

export default CompanyImages
