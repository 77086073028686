import React from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { makeStyles, useTheme } from "@material-ui/core/styles";

import TicketsManager from "../../components/TicketsManagerTabs/";
import Ticket from "../../components/Ticket/";
import useImages from "../../hooks/useImages";


const useStyles = makeStyles(theme => ({
    chatContainer: {
        flex: 1,
        padding: theme.spacing(0), //Aqui ele ajusta espaço na tela de ticket
        height: `calc(100% - ${theme.appBarHeight})`,
        overflowY: 'hidden',
    },

    chatPapper: {
        display: 'flex',
        height: '100%',
    },

    contactsWrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        overflowY: 'hidden',
    },
    messagesWrapper: {
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
    },
    welcomeMsg: {
        backgroundColor: theme.palette.boxticket, //DARK MODE //
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        height: '100%',
        textAlign: 'center',
        borderRadius: '8px',
    },
}))

const TicketsCustom = () => {
    const classes = useStyles();
    const theme = useTheme();
    const { ticketId } = useParams();
    const { logo, logoDark } = useImages()

    return (
        <div className={classes.chatContainer}>
            <div className={classes.chatPapper}>
                <Grid container>
                    <Grid item xs={4} className={classes.contactsWrapper}>
                        <TicketsManager />
                    </Grid>
                    <Grid item xs={8} className={classes.messagesWrapper}>
                        {ticketId ? (
                            <>
                                <Ticket />
                            </>
                        ) : (
                            <Paper square variant="outlined" className={classes.welcomeMsg}>
                                <div>
                                    <center>
                                        <img
                                            style={{ margin: "0 auto", width: "70%" }}
                                            src={theme.mode === "light" ? logo : logoDark}
                                            alt="logologin"
                                        />
                                    </center>
                                </div>
                            </Paper>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

export default TicketsCustom;
