import React, { useContext, useEffect, useState } from 'react'
import { Link as RouterLink } from 'react-router-dom'

import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { Badge } from '@material-ui/core'
import { WhatsAppsContext } from '../context/WhatsApp/WhatsAppsContext'
import { Can } from '../components/Can'
import MegaPhone from 'icons/MegaPhone'
import useAuth from 'hooks/useAuth.js'
import {
    AccountTreeOutlined,
    ContactPhoneOutlined,
    DashboardOutlined,
    FlashOn,
    LibraryAdd,
    LinearScale,
    LocalOffer,
    Lock,
    PeopleAltOutlined,
    PersonOutlineOutlined,
    SettingsOutlined,
    SyncAlt,
    WhatsApp,
} from '@material-ui/icons'

function ListItemLink(props) {
    const { icon, primary, to, className } = props

    const renderLink = React.useMemo(
        () =>
            React.forwardRef((itemProps, ref) => (
                <RouterLink to={to} ref={ref} {...itemProps} />
            )),
        [to],
    )

    return (
        <li>
            <ListItem button dense component={renderLink} className={className}>
                {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
                <ListItemText primary={primary} />
            </ListItem>
        </li>
    )
}

const MainListItems = props => {
    const { drawerClose } = props
    const { whatsApps } = useContext(WhatsAppsContext)
    const [connectionWarning, setConnectionWarning] = useState(false)
    const { user } = useAuth()

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (whatsApps.length > 0) {
                const offlineWhats = whatsApps.filter(whats => {
                    return (
                        whats.status === 'qrcode' ||
                        whats.status === 'PAIRING' ||
                        whats.status === 'DISCONNECTED' ||
                        whats.status === 'TIMEOUT' ||
                        whats.status === 'OPENING'
                    )
                })
                if (offlineWhats.length > 0) {
                    setConnectionWarning(true)
                } else {
                    setConnectionWarning(false)
                }
            }
        }, 2000)
        return () => clearTimeout(delayDebounceFn)
    }, [whatsApps])

    return (
        <div onClick={drawerClose}>
            <Can
                perform={[
                    'dashboard:page:general',
                    'dashboard:page:tickets',
                    'dashboard:page:metrics',
                ]}>
                <ListItemLink
                    to="/"
                    primary="Dashboard"
                    icon={<DashboardOutlined />}
                />
            </Can>

            <ListItemLink
                to="/tickets"
                primary="Atendimentos"
                icon={<WhatsApp />}
            />

            <Can perform="quickMessage:page">
                <ListItemLink
                    to="/quick-messages"
                    primary="Respostas Rápidas"
                    icon={<FlashOn />}
                />
            </Can>

            <Can perform="contact:page">
                <ListItemLink
                    to="/contacts"
                    primary="Contatos"
                    icon={<ContactPhoneOutlined />}
                />
            </Can>

            <Can perform="tag:page">
                <ListItemLink to="/tags" primary="Tags" icon={<LocalOffer />} />
            </Can>

            <Can perform="customField:page">
                <ListItemLink
                    to="/custom-field"
                    primary="Campos Customizados"
                    icon={<LibraryAdd />}
                />
            </Can>

            <Can perform="connection:page">
                <ListItemLink
                    to="/connections"
                    primary="Conexões"
                    icon={
                        <Badge
                            badgeContent={connectionWarning ? '!' : 0}
                            color="error">
                            <SyncAlt />
                        </Badge>
                    }
                />
            </Can>

            <Can perform="queue:page">
                <ListItemLink
                    to="/queues"
                    primary="Setores"
                    icon={<AccountTreeOutlined />}
                />
            </Can>

            <Can perform="transmission:page">
                <ListItemLink
                    to="/transmission"
                    primary="Lista de Transmissão"
                    icon={<MegaPhone />}
                />
            </Can>

            <Can perform="chatFlow:page">
                <ListItemLink
                    to="/chat-flows"
                    primary="ChatBot"
                    icon={<LinearScale />}
                />
            </Can>

            <Can perform="user:page">
                <ListItemLink
                    to="/users"
                    primary="Usuários"
                    icon={<PersonOutlineOutlined />}
                />
            </Can>

            <Can perform="role:page">
                <ListItemLink
                    to="/roles"
                    primary="Cargos"
                    icon={<PeopleAltOutlined />}
                />
            </Can>

            <Can perform="setting:page">
                <ListItemLink
                    to="/settings"
                    primary="Configurações"
                    icon={<SettingsOutlined />}
                />
            </Can>

            {user.super && (
                <ListItemLink
                    to="/test"
                    primary="Página de Testes"
                    icon={<Lock />}
                />
            )}

            {/* <Can perform="customField:page">
                <ListItemLink
                    to="/queue-integration"
                    primary={'Integrações'}
                    icon={<DeviceHubOutlined />}
                />
            </Can> */}

            {/* <Can perform="customField:page">
                <ListItemLink
                    to="/messages-api"
                    primary={'API de Mensagens'}
                    icon={<CodeRoundedIcon />}
                />
            </Can> */}
        </div>
    )
}

export default MainListItems
