import TitleWithButton from 'components/Title/TitleWithButton'
import IframeModal from './IframeModal'
import { useState } from 'react'
import useSettings from 'hooks/useSettings'
import {
    Grid,
    Button,
    IconButton,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    Table,
    TableHead,
    Typography,
} from '@material-ui/core'
import { DeleteOutline, Edit } from '@material-ui/icons'
import toastError from 'errors/toastError'
import ConfirmationModal from 'components/ConfirmationModal'

const IframeGrid = ({ iframes, openUpdate, openDelete }) => {
    return (
        <Paper variant="outlined">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Nome</TableCell>
                        <TableCell align="center">Link</TableCell>
                        <TableCell align="center">Ativo/Inativo</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {iframes.map(iframe => (
                        <TableRow key={iframe.id}>
                            <TableCell align="center">{iframe.name}</TableCell>
                            <TableCell align="center">{iframe.link}</TableCell>
                            <TableCell align="center">
                                {iframe.active ? 'Ativo' : 'Inativo'}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    size="small"
                                    onClick={() => openUpdate(iframe)}>
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        openDelete(iframe)
                                    }}>
                                    <DeleteOutline />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}

const Iframe = ({ settings }) => {
    const { setSetting } = useSettings()
    const [iframeModalOpen, setIframeModalOpen] = useState(false)
    const [formUpdating, setFormUpdating] = useState()
    const [deleting, setDeleting] = useState()

    const iframes = settings.iframes ?? []

    const handleSetForm = form => {
        const idx = iframes.findIndex(s => s.uuid === form.uuid)

        if (idx === -1) iframes.push(form)
        else iframes[idx] = form

        setSetting('iframes', iframes)
    }

    const handleModalClose = () => {
        setFormUpdating(null)
        setIframeModalOpen(false)
    }

    const handleDelete = () => {
        const idx = iframes.findIndex(s => s.uuid === deleting.uuid)
        if (idx !== -1) {
            iframes.splice(idx, 1)
            setSetting('iframes', iframes)
        } else {
            toastError('Este iframe não foi encontrado para excluir!')
        }

        setDeleting(null)
    }

    return (
        <>
            <ConfirmationModal
                title="Excluindo Iframe"
                open={Boolean(deleting)}
                onClose={() => setDeleting(null)}
                onConfirm={() => handleDelete()}>
                Deseja realmente excluir esse iframe?
            </ConfirmationModal>
            <IframeModal
                open={iframeModalOpen}
                form={formUpdating}
                update={handleSetForm}
                onClose={handleModalClose}
            />
            <Grid spacing={3} container>
                <TitleWithButton title="Iframes">
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => setIframeModalOpen(true)}>
                        Adicionar
                    </Button>
                </TitleWithButton>

                <Grid item xs={12}>
                    <Typography variant="caption">
                        Iframe é uma tecnologia de site sobre site, ou seja,
                        você pode criar uma tela de um site/sistema qualquer
                        apenas passando a URL do mesmo. Os iframes criados aqui ficarão disponíveis na tela de
                        atendimento, para acessá-los, basta clicar nos 3 pontos
                        sobre o atendimento e selecionar o iframe que desejar
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <IframeGrid
                        iframes={iframes}
                        openUpdate={iframe => {
                            setFormUpdating(iframe)
                            setIframeModalOpen(true)
                        }}
                        openDelete={setDeleting}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default Iframe
