import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    Switch,
    TextField,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import { useFormik } from 'formik'
import Validator from 'helpers/Validator'
import { useEffect } from 'react'
import { v4 as uuid } from 'uuid'

const IframeSchema = Validator.object().shape({
    name: Validator.string().required(),
    link: Validator.string()
        .matches(
            /^(https?:\/\/(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,})(\/[^\s]*)?(\?(?:[a-zA-Z0-9\-._~%!$&'()*+,;=:@/?]*={\w+}&?)*)?$/,
            {
                name: 'urlWithParams',
                excludeEmptyString: true,
                message: 'Link precisa ser uma URL válida',
            },
        )
        .required(),
    width: Validator.number().max(1000).min(50).required().label('Largura'),
})

const IframeModal = ({ form = {}, update, open, onClose, ...props }) => {
    const formik = useFormik({
        initialValues: {
            uuid: uuid(),
            name: '',
            link: '',
            width: '320',
            active: true,
            ...form,
        },
        onSubmit: values => {
            update(values)
            handleClose()
        },
        validationSchema: IframeSchema,
    })

    useEffect(() => {
        if (!open || !form || !Object.keys(form)?.length) return

        formik.setValues(form)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form])

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {form?.name ? 'Editando' : 'Cadastrando'} Iframe
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Alert icon={false}>
                            Você pode utilizar algumas variáveis na URL:
                            <ul>
                                <li>
                                    <b>{'{contactId}'}</b>: ID do contato
                                </li>
                                <li>
                                    <b>{'{contactNumber}'}</b>: Número do
                                    contato
                                </li>
                                <li>
                                    <b>{'{contactEmail}'}</b>: E-mail do contato
                                </li>
                                <li>
                                    <b>{'{ticketId}'}</b>: ID do atendimento
                                </li>
                                <li>
                                    <b>{'{ticketUserId}'}</b>: ID do usuário
                                    atendendo
                                </li>
                                <li>
                                    <b>{'{userId}'}</b>: ID do usuário logado
                                </li>
                            </ul>
                            Caso não tenha valor definido, como por exemplo, se
                            não tiver usuário atendendo, ou se o contato não
                            tiver e-mail, será usado <b>'null'</b>
                        </Alert>

                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Nome"
                                value={formik.values.name}
                                name="name"
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors?.name)}
                                helperText={formik.errors?.name}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Link"
                                value={formik.values.link}
                                name="link"
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors?.link)}
                                helperText={formik.errors?.link}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Largura (px)"
                                value={formik.values.width}
                                type="number"
                                name="width"
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors?.width)}
                                helperText={formik.errors?.width}
                            />
                        </Grid>

                        <FormControlLabel
                            label="Ativo"
                            control={
                                <Switch
                                    checked={formik.values.active}
                                    onChange={formik.handleChange}
                                    name="active"
                                    color="primary"
                                />
                            }
                        />
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={formik.handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default IframeModal
