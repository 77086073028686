import { useState, useEffect } from 'react'
import toastError from '../../errors/toastError'

import api from '../../services/api'

const useTickets = ({
    searchParam,
    tags,
    users,
    connections,
    isGroup,
    pageNumber,
    status,
    date,
    updatedAt,
    sort,
    queueIds,
    messagesBetween,
    unansweredMessages,
    withUnreadMessages,
}) => {
    const [loading, setLoading] = useState(true)
    const [hasMore, setHasMore] = useState(false)
    const [tickets, setTickets] = useState([])
    const [total, setTotal] = useState(0)

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchTickets = async () => {
                try {
                    const { data } = await api.get('/tickets', {
                        filters: {
                            smart: searchParam,
                            tagIn: tags,
                            userIn: users,
                            connections: connections,
                            queueIn: withUnreadMessages ? null : queueIds,
                            isGroup: isGroup,
                            statusIn: status,
                            createdDay: date,
                            updatedDay: updatedAt,
                            messagesBetween: messagesBetween,
                            unansweredMessages: unansweredMessages,
                            withUnreadMessages,
                        },
                        include: {
                            contact: '*',
                            user: '*',
                            queue: '*',
                            whatsapp: 'id,name',
                            tags: '*',
                        },
                        appends: 'isPinned',
                        page: pageNumber,
                        sort: sort || '-updatedAt',
                    })
                    setTickets(data.data)
                    setHasMore(tickets.length < data.total)
                    setTotal(data.total)
                    setLoading(false)
                } catch (err) {
                    setLoading(false)
                    toastError(err)
                }
            }
            fetchTickets()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        searchParam,
        tags,
        users,
        connections,
        isGroup,
        pageNumber,
        status,
        date,
        updatedAt,
        sort,
        queueIds,
        withUnreadMessages,
        messagesBetween,
        unansweredMessages
    ])

    return { tickets, loading, hasMore, total }
}

export default useTickets
