import React from 'react'
import clsx from 'clsx'

import useStyle from './useStyle'

import { Box, Grid, IconButton, Paper, Typography, useTheme } from '@material-ui/core'
import ModalImage from 'react-modal-image'
import {
    loadFile,
    areFileExtensionValid,
    loadImage,
    areImageDimsValid,
    resizeImage as resizeImageUtil,
} from './utils'
import { toast } from 'react-toastify'
import useMediaUpload from 'hooks/useMediaUpload'
import { Delete } from '@material-ui/icons'

export const ACCEPTS_DEFAULT = [
    'png',
    'jpg',
    'gif',
    'jpeg',
    'doc',
    'docx',
    'xls',
    'csv',
    'txt',
    'xlsx',
    'ppt',
    'pptx',
    'pdf',
    'ogg',
    'oga',
    'mp4',
    'mp3',
    'mpeg',
    'webp',
]
export const ACCEPTS_IMAGE = ['png', 'jpg', 'jpeg', 'webp', 'gif']

const BYTES_PER_MEGABYTE = 1000000

/**
 * FilePicker component allows users to select and upload files.
 * It supports various file types, image resizing, and dimension validation.
 * The component can be customized with different props for appearance and behavior.
 *
 * Available ref methods:
 * - getUploading: Returns the current uploading state.
 * - getInputRef: Returns the input element reference.
 * - getFiles: Returns the list of selected files.
 * - clearFiles: Clears the list of selected files.
 * - handleFileChange: Handles file selection changes.
 *
 * @param {Function} onFileChange - Callback function called when files are selected or changed.
 * @param {string} form - Identifier for the form.
 * @param {Array} initial - Initial set of files.
 * @param {boolean} showArea - Whether to show the file drop area.
 * @param {boolean} reverseTheme - Theme reversal flag.
 * @param {boolean} resetOnChange - Whether to reset files on change.
 * @param {boolean} fullWidth - Whether to use full width for the drop area.
 * @param {boolean} multiple - Support for selecting multiple files.
 * @param {string} height - Height of the file drop area.
 * @param {Array} accepts - Array of accepted file extensions.
 * @param {number} imageQuality - Quality factor for image resizing.
 * @param {number} minFileSize - Minimum file size in MB.
 * @param {number} maxFileSize - Maximum file size in MB.
 * @param {number} minImageWidth - Minimum width for uploaded images.
 * @param {number} maxImageWidth - Maximum width for uploaded images.
 * @param {number} minImageHeight - Minimum height for uploaded images.
 * @param {number} maxImageHeight - Maximum height for uploaded images.
 * @param {boolean} resizeImage - Flag to resize images to fit dimensions.
 * @param {Object} ref - React ref for imperative handle.
 */
const FilePicker = (
    {
        onFileChange,
        form,
        initial = [],
        showArea = true,
        reverseTheme = false,
        resetOnChange = false,
        fullWidth = false,
        multiple = false,
        height = '12rem',
        accepts = ACCEPTS_DEFAULT,
        imageQuality = 1,

        minFileSize,
        maxFileSize,
        minImageWidth,
        maxImageWidth,
        minImageHeight,
        maxImageHeight,
        resizeImage,
    },
    ref,
) => {
    const { uploadFile } = useMediaUpload()
    const classes = useStyle({ height })()
    const theme = useTheme()

    const refFirstRender = React.useRef(true)
    const refUploading = React.useRef(false)
    const refInput = React.useRef(null)

    const [files, setFiles] = React.useState(
        Array.isArray(initial) ? initial : [initial],
    )

    const handleFileChange = async files => {
        let filesSelected = Array.from(files).filter((file, index) =>
            multiple ? file : index === 0,
        )

        if (!filesSelected.length)
            return toast.error('Nenhum arquivo selecionado')

        if (filesSelected.some(file => !areFileExtensionValid(file, accepts)))
            return toast.error('Formato de arquivo inválido')

        if (
            minFileSize &&
            filesSelected.some(
                file => file.size < BYTES_PER_MEGABYTE * minFileSize,
            )
        )
            return toast.error('Arquivo com tamanho muito pequeno')

        if (
            maxFileSize &&
            filesSelected.some(
                file => file.size > BYTES_PER_MEGABYTE * maxFileSize,
            )
        )
            return toast.error('Arquivo com tamanho muito grande')

        refUploading.current = true

        const dimensions = {
            minImageWidth,
            maxImageWidth,
            minImageHeight,
            maxImageHeight,
        }
        if (
            accepts === ACCEPTS_IMAGE &&
            Object.values(dimensions).some(Boolean)
        ) {
            const dataUrls = await Promise.all(filesSelected.map(loadFile))
            const images = await Promise.all(dataUrls.map(loadImage))

            const hasImageWithInvalidDims = images.some(
                image => !areImageDimsValid(image, dimensions),
            )
            if (
                (hasImageWithInvalidDims && resizeImage) ||
                (!hasImageWithInvalidDims && imageQuality)
            ) {
                const resizedImages = await Promise.all(
                    images.map((image, index) => {
                        const imageType = filesSelected[index].type
                        const maxSize =
                            Math.max(maxImageWidth || 0, maxImageHeight || 0) ||
                            Math.max(image.width, image.height)
                        return imageType === 'image/gif'
                            ? filesSelected[index]
                            : resizeImageUtil(
                                  image,
                                  maxSize,
                                  imageType,
                                  imageQuality,
                              )
                    }),
                )
                filesSelected = resizedImages.map((blob, index) =>
                    blob instanceof File
                        ? blob
                        : new File([blob], filesSelected[index].name, {
                              type: filesSelected[index].type,
                              lastModified: Date.now(),
                          }),
                )
            }
        }

        for (const index in filesSelected) {
            const file = filesSelected[index]
            const { filename, originalname, blob } = await uploadFile(file)
            filesSelected[index] = {
                fileName: form,
                name: originalname,
                stored: filename,
                url: blob,
            }
        }

        setFiles(prev =>
            resetOnChange ? [...filesSelected] : [...prev, ...filesSelected],
        )

        refUploading.current = false
        if (refInput?.current) refInput.current.value = ''
    }

    const handleDragOver = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        ev.dataTransfer.dropEffect = 'copy'
    }

    const handleDrop = ev => {
        ev.preventDefault()
        ev.stopPropagation()
        handleFileChange(ev.dataTransfer.files)
    }

    const handleChange = ev => {
        handleFileChange(ev.target.files)
    }

    const handleDelete = file => {
        const fileIndex = files.indexOf(file)
        const newFiles = [...files]
        newFiles.splice(fileIndex, 1)
        setFiles(newFiles)
    }

    React.useEffect(() => {
        if (refFirstRender.current) {
            refFirstRender.current = false
            return
        }

        onFileChange?.(multiple ? files : files.at(0))
        // eslint-disable-next-line
    }, [files])

    React.useImperativeHandle(
        ref,
        () => ({
            getUploading: () => refUploading,
            getInputRef: () => refInput,
            getFiles: () => files,
            clearFiles: () => setFiles([]),
            handleFileChange,
        }),

        // eslint-disable-next-line
        [files],
    )

    const hasFile = files.length > 0

    return (
        <Paper
            elevation={2}
            theme={theme}
            className={clsx([
                classes.elevation,
                {
                    [classes.fitContent]: !fullWidth && !multiple,
                    [classes.lightPaper]:
                        theme.mode === 'dark' && reverseTheme === 'light',
                    [classes.darkPaper]:
                        theme.mode === 'light' && reverseTheme === 'dark',
                },
            ])}>
            <Grid
                container
                spacing={2}
                className={clsx([
                    classes.container,
                    { [classes.uniqueFile]: fullWidth && !multiple },
                ])}>
                {showArea && (multiple || !hasFile) && (
                    <Box
                        className={clsx([
                            classes.containerUpload,
                            { [classes.fullWidth]: fullWidth && !multiple },
                        ])}
                        onClick={() => refInput.current?.click()}
                        onDragOver={handleDragOver}
                        onDrop={handleDrop}>
                        <Typography variant="button">
                            Clique ou Arraste
                        </Typography>
                        <Typography variant="button">para</Typography>
                        <Typography variant="button">
                            Adicionar Arquivo{multiple && 's'}
                        </Typography>
                        <input
                            type="file"
                            aria-label="add_files"
                            ref={refInput}
                            multiple={multiple}
                            onChange={handleChange}
                            style={{ display: 'none' }}
                            accept={`.${accepts.join(',.')}`}
                        />
                    </Box>
                )}

                {files.map((file, i) => (
                    <Box className={classes.containerImage} key={i}>
                        <ModalImage
                            className={`modalImage ${classes.modalImage}`}
                            smallSrcSet={file.url}
                            medium={file.url}
                            large={file.url}
                            alt={i + 1}
                            key={i}
                        />
                        <IconButton
                            className={classes.deleteButton}
                            onClick={() => handleDelete(file)}>
                            <Delete color="secondary" />
                        </IconButton>
                    </Box>
                ))}
            </Grid>
        </Paper>
    )
}

export default React.forwardRef(FilePicker)

