import React, { useImperativeHandle } from "react";
import DailyTimestamp from "./DailyTimestamp";
import Divider from "./Divider";
import TicketNumber from "./TicketNumber";
import useStyles from "../useStyles";
import clsx from 'clsx'
import { IconButton } from "@material-ui/core";
import { Block, ExpandMore } from "@material-ui/icons";
import Ack from "./Ack";
import { format, parseISO } from "date-fns";
import MarkdownWrapper from "components/MarkdownWrapper";
import Quoted from "./Quoted";
import RenderMessageMedia from "./RenderMessageMedia";
import Reaction from "./Reaction";


const Message = ({
    side,
    ticket,
    currentMessage,
    previousMessage,
    messageFocusedId,
    handleActions,
}, ref) => {
    const classes = useStyles()

    const refHtml = React.useRef(null)

    useImperativeHandle(ref, () => ({
        getRef: () => refHtml,
        scroll: () => refHtml.current.scrollIntoView(),
    }), []);

    const { fromMe: isFromMe, isDeleted, isEdited } = currentMessage
    const { isGroup, status } = ticket

    const isOpen = status === 'open'
    const hasMedia = ['audio', 'image', 'video', 'sticker', 'document', 'documentWithCaption'].includes(currentMessage.messageType)
    let messageBody = ['contactMessage', 'locationMessage', 'liveLocationMessage', 'contactsArrayMessage'].includes(currentMessage.messageType) ? null : currentMessage.body.replace(`\u200e`, ``)

    return (
        <>
            <DailyTimestamp {...{ currentMessage, previousMessage }} />
            <TicketNumber {...{ currentMessage, previousMessage }} />
            <Divider {...{ currentMessage, previousMessage }} />

            <div
                ref={refHtml}
                className={clsx(classes.message, {
                    [classes.fromApi]: currentMessage.fromApi,
                    [classes.focused]: messageFocusedId === currentMessage.id,
                    [classes.left]: side === SIDES.LEFT,
                    [classes.right]: side === SIDES.RIGHT,
                    focused: messageFocusedId === currentMessage.id,
                    left: side === SIDES.LEFT,
                    right: side === SIDES.RIGHT,

                })}
            >
                {isOpen && !isDeleted &&
                    <IconButton
                        size="small"
                        variant="contained"
                        className={clsx([classes.actionsButton, 'actionsButton'])}
                        onClick={e => handleActions?.(e, currentMessage)}
                    >
                        <ExpandMore />
                    </IconButton>}

                {isGroup && !isFromMe && (
                    <div className={`${classes.contact} contact`}>
                        {currentMessage.participantContact?.name}
                    </div>
                )}

                {currentMessage.quotedMsg && <Quoted {...{ currentMessage }} />}

                {(hasMedia || currentMessage.mediaUrl || ['contactMessage', 'locationMessage', 'liveLocationMessage', 'contactsArrayMessage'].includes(currentMessage.messageType)) && (
                    <RenderMessageMedia message={currentMessage} />
                )}

                <div
                    className={clsx(
                        classes.text,
                        {
                            [classes.deleted]: isDeleted,
                            [classes.edited]: isEdited,
                        }
                    )}
                >
                    {isDeleted && (
                        <Block
                            color="disabled"
                            fontSize="small"
                            className={classes.deletedIcon}
                        />
                    )}

                    <div className={clsx({ [classes.body]: !!messageBody }, 'body')} >
                        <MarkdownWrapper>
                            {messageBody}
                        </MarkdownWrapper>
                    </div>
                </div>

                <span className={classes.information} >
                    {isEdited && <span>Editada</span>}
                    {format(parseISO(currentMessage.createdAt), 'HH:mm')}
                    <Ack {...{ currentMessage }} />
                </span>

                <Reaction {...{ currentMessage }} />
            </div>

        </>
    )
}

export default React.forwardRef(Message)

export const SIDES = {
    LEFT: 'left',
    RIGHT: 'right'
}
