import { IconButton, makeStyles, Popover, useTheme } from '@material-ui/core'
import { Picker } from 'emoji-mart'
import api from 'services/api'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import { useState } from 'react'

const useStyles = makeStyles(() => ({
    buttonsPopover: {
        borderRadius: 16,
        padding: 3
    }
}))

const ReactingMessage = ({ message, anchorEl, open, onClose }) => {
    const theme = useTheme()
    const classes = useStyles()
    const [pickerOpened, setPickerOpened] = useState(false)

    const handleClose = () => {
        setPickerOpened(false)
        onClose()
    }

    const handleReact = async emoji => {
        await api.post(`/messages/${message.ticketId}`, {
            related: {
                id: message.id,
                uuid: message.uuid
            },
            relatedType: 'react',
            body: emoji,
        })

        handleClose()
    }

    return (
        <>
            <Popover
                open={open && !pickerOpened}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                classes={{
                    paper: classes.buttonsPopover
                }}>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleReact('👍')}>
                    👍
                </IconButton>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleReact('❤️')}>
                    ❤️
                </IconButton>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleReact('😂')}>
                    😂
                </IconButton>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleReact('😦')}>
                    😦
                </IconButton>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleReact('😥')}>
                    😥
                </IconButton>
                <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleReact('🙏')}>
                    🙏
                </IconButton>
                <IconButton size="small" onClick={() => setPickerOpened(true)}>
                    <ArrowDropDownIcon />
                </IconButton>
            </Popover>

            <Popover
                open={open && pickerOpened}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <Picker
                    perLine={16}
                    showPreview={false}
                    showSkinTones={false}
                    onSelect={e => handleReact(e.native)}
                    theme={theme.mode}
                />
            </Popover>
        </>
    )
}

export default ReactingMessage
