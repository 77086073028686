import React, { useState, useContext, useEffect } from 'react'
import clsx from 'clsx'
import {
    makeStyles,
    Drawer,
    AppBar,
    Toolbar,
    List,
    Typography,
    Divider,
    MenuItem,
    IconButton,
    Menu,
    useTheme,
} from '@material-ui/core'

import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import AccountCircle from '@material-ui/icons/AccountCircle'
import CachedIcon from '@material-ui/icons/Cached'

import MainListItems from './MainListItems'
import NotificationsPopOver from '../components/NotificationsPopOver'
import NotificationsVolume from '../components/NotificationsVolume'
import UserModal from '../components/UserModal'
import { AuthContext } from '../context/Auth/AuthContext'
import BackdropLoading from '../components/BackdropLoading'
import toastError from '../errors/toastError'

import logoDefault from "../assets/logo.png"
import logoDefaultDark from "../assets/logo-white.png"
import useImages from '../hooks/useImages'

import { SocketContext } from '../context/Socket/SocketContext'
import ColorModeContext from '../layout/themeContext'
import Brightness4Icon from '@material-ui/icons/Brightness4'
import Brightness7Icon from '@material-ui/icons/Brightness7'

const drawerWidth = 240

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        height: '100dvh',
        backgroundColor: theme.palette.fancyBackground,
        '& .MuiButton-outlinedPrimary': {
            color: theme.mode === 'light' ? '#FFF' : '#FFF',
            backgroundColor: theme.mode === 'light' ? '#284359' : '#1c1c1c',
            //border: theme.mode === 'light' ? '1px solid rgba(0 124 102)' : '1px solid rgba(255, 255, 255, 0.5)',
        },
        '& .MuiTab-textColorPrimary.Mui-selected': {
            color: theme.mode === 'light' ? '#284359' : '#FFF',
        },
    },
    avatar: {
        width: '100%',
    },
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
        color: theme.palette.dark.main,
        background: theme.palette.headerBackground,
    },
    toolbarIcon: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: '0 8px',
        minHeight: '48px',
        [theme.breakpoints.down('sm')]: {
            height: '48px',
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
    },
    appBarShift: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
    },
    menuButton: {
        marginRight: 36,
    },
    menuButtonHidden: {
        display: 'none',
    },
    title: {
        flexGrow: 1,
        fontSize: 14,
        color: 'white',
    },
    drawerPaper: {
        position: 'relative',
        whiteSpace: 'nowrap',
        width: drawerWidth,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        ...theme.scrollbarStylesSoft,
    },
    drawerPaperClose: {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
            width: theme.spacing(9),
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    appBarSpacer: {
        minHeight: theme.appBarHeight,
    },
    content: {
        flex: 1,
        overflow: 'auto',
    },
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    containerWithScroll: {
        flex: 1,
        padding: theme.spacing(1),
        // overflowY: "scroll",
        ...theme.scrollbarStyles,
    },
    NotificationsPopOver: {
        // color: theme.barraSuperior.secondary.main,
    },
    logo: {
        width: '80%',
        height: 'auto',
        maxWidth: 180,
        padding: 15,
        [theme.breakpoints.down('sm')]: {
            height: '80%',
            maxWidth: 180,
            maxHeight: 50,
            padding: 4,
        },
        logo: theme.logo,
    },
    logoApre: {
        position: 'absolute',
        bottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginBottom: 8,
            maxHeight: 40,
        },
    }
}))

const LoggedInLayout = ({ children, themeToggle }) => {
    const classes = useStyles()
    const [userModalOpen, setUserModalOpen] = useState(false)
    const [anchorEl, setAnchorEl] = useState(null)
    const [menuOpen, setMenuOpen] = useState(false)
    const { handleLogout, loading } = useContext(AuthContext)
    const [drawerOpen, setDrawerOpen] = useState(false)
    const [drawerVariant, setDrawerVariant] = useState('permanent')
    const { user } = useContext(AuthContext)

    const theme = useTheme()
    const { colorMode } = useContext(ColorModeContext)
    const [volume, setVolume] = useState(localStorage.getItem('volume') || 1)
    const socketManager = useContext(SocketContext)

    const { logo, logoDark } = useImages()


    useEffect(() => {
        if (document.body.offsetWidth > 1600) {
            setDrawerOpen(true)
        }
    }, [])

    useEffect(() => {
        if (document.body.offsetWidth < 960) {
            setDrawerVariant('temporary')
        } else {
            setDrawerVariant('permanent')
        }
    }, [drawerOpen])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const userId = localStorage.getItem('userId')

        const socket = socketManager.GetSocket(companyId)

        socket.on(`company-${companyId}-auth`, data => {
            if (data.user.id === +userId) {
                toastError('Sua conta foi acessada em outro computador.')
                setTimeout(() => {
                    localStorage.clear()
                    window.location.reload()
                }, 1000)
            }
        })

        socket.emit('userStatus')
        const interval = setInterval(() => {
            socket.emit('userStatus')
        }, 1000 * 60 * 5)

        return () => {
            socket.disconnect()
            clearInterval(interval)
        }
    }, [socketManager])

    const handleMenu = event => {
        setAnchorEl(event.currentTarget)
        setMenuOpen(true)
    }

    const handleCloseMenu = () => {
        setAnchorEl(null)
        setMenuOpen(false)
    }

    const handleOpenUserModal = () => {
        setUserModalOpen(true)
        handleCloseMenu()
    }

    const handleClickLogout = () => {
        handleCloseMenu()
        handleLogout()
    }

    const drawerClose = () => {
        if (document.body.offsetWidth < 960) {
            setDrawerOpen(false)
        }
    }

    const handleRefreshPage = () => {
        window.location.reload(false)
    }

    const toggleColorMode = () => {
        colorMode.toggleColorMode()
    }

    if (loading) {
        return <BackdropLoading />
    }

    return (
        <div className={classes.root}>
            {!window.isEmbeded && (
                <>
                    <Drawer
                        variant={drawerVariant}
                        className={
                            drawerOpen
                                ? classes.drawerPaper
                                : classes.drawerPaperClose
                        }
                        classes={{
                            paper: clsx(
                                classes.drawerPaper,
                                !drawerOpen && classes.drawerPaperClose,
                            ),
                        }}
                        open={drawerOpen}>
                        <div className={classes.toolbarIcon}>
                            <img
                                src={theme.mode === 'light' ? logo : logoDark}
                                className={classes.logo}
                                alt="logo"
                            />
                            <IconButton
                                onClick={() => setDrawerOpen(!drawerOpen)}>
                                <ChevronLeftIcon />
                            </IconButton>
                        </div>
                        <Divider />
                        <List className={classes.containerWithScroll}>
                            <MainListItems
                                drawerClose={drawerClose}
                                collapsed={!drawerOpen}
                            />
                            <img
                                src={theme.mode === 'light' ? logoDefault : logoDefaultDark}
                                className={`${classes.logo} ${classes.logoApre}`}
                                alt="logo"
                            />
                        </List>
                        <Divider />
                    </Drawer>
                    <UserModal
                        open={userModalOpen}
                        onClose={() => setUserModalOpen(false)}
                        userId={user?.id}
                        isPerfil
                    />
                    <AppBar
                        position="absolute"
                        className={clsx(
                            classes.appBar,
                            drawerOpen && classes.appBarShift,
                        )}
                        color="primary">
                        <Toolbar variant="dense" className={classes.toolbar}>
                            <IconButton
                                edge="start"
                                variant="contained"
                                aria-label="open drawer"
                                onClick={() => setDrawerOpen(!drawerOpen)}
                                className={clsx(
                                    classes.menuButton,
                                    drawerOpen && classes.menuButtonHidden,
                                )}>
                                <MenuIcon />
                            </IconButton>

                            <Typography
                                component="h2"
                                variant="h6"
                                color="inherit"
                                noWrap
                                className={classes.title}>
                                Olá <b>{user.name}</b>, Bem vindo a{' '}
                                <b>{user?.company?.name}</b>!
                            </Typography>

                            <IconButton edge="start" onClick={toggleColorMode}>
                                {theme.mode === 'dark' ? (
                                    <Brightness7Icon
                                        style={{ color: 'white' }}
                                    />
                                ) : (
                                    <Brightness4Icon
                                        style={{ color: 'white' }}
                                    />
                                )}
                            </IconButton>

                            <NotificationsVolume
                                setVolume={setVolume}
                                volume={volume}
                            />

                            <IconButton
                                onClick={handleRefreshPage}
                                aria-label="Recarregar"
                                color="inherit">
                                <CachedIcon style={{ color: 'white' }} />
                            </IconButton>

                            {user.id && (
                                <NotificationsPopOver volume={volume} />
                            )}

                            <div>
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    onClick={handleMenu}
                                    variant="contained"
                                    style={{ color: 'white' }}>
                                    <AccountCircle />
                                </IconButton>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    getContentAnchorEl={null}
                                    anchorOrigin={{
                                        vertical: 'bottom',
                                        horizontal: 'right',
                                    }}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={menuOpen}
                                    onClose={handleCloseMenu}>
                                    <MenuItem onClick={handleOpenUserModal}>
                                        Perfil
                                    </MenuItem>
                                    <MenuItem onClick={handleClickLogout}>
                                        Sair
                                    </MenuItem>
                                </Menu>
                            </div>
                        </Toolbar>
                    </AppBar>
                </>
            )}

            <main className={classes.content}>
                <div className={classes.appBarSpacer} />

                {children ? children : null}
            </main>
        </div>
    )
}

export default LoggedInLayout
