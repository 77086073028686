import ApiTokens from "./ApiTokens"
import Iframe from "./Iframe"

const Integrations = ({ settings }) => {

    return (
        <>
            <ApiTokens settings={settings}></ApiTokens>

            <br />
            <hr />
            <br />

            <Iframe settings={settings}></Iframe>
        </>
    )
}

export default Integrations
