import React from "react";

import Paper from "@material-ui/core/Paper";
import Skeleton from "@material-ui/lab/Skeleton";

import moment from "moment";

import Rating from "@material-ui/lab/Rating";
import { Chip, Grid, Tooltip, Typography } from "@material-ui/core";
import Title from "pages/Dashboard/components/Title";
import { Person } from "@material-ui/icons";

const customIcons = {
    0: {icon: "🚫", label: "Sem Avaliação"},
    1: {icon: "😡", rate:1, label: "Muito Insatisfeito" },
    2: {icon: "😤", rate:2, label: "Insatisfeito" },
    3: {icon: "😐", rate:3, label: "Indiferente" },
    4: {icon: "😀", rate:4, label: "Satisfeito" },
    5: {icon: "🤩", rate:5, label: "Muito Satisfeito" },
}

export function RatingBox({ rating }) {
    const ratingTrunc = rating && rating > 0 ? rating.toFixed(1) : 0
    const ratingValue = parseFloat((ratingTrunc % 1).toFixed(1))
    const rated = customIcons[Math.ceil(ratingTrunc)]
    return (
        <Tooltip title={`${ratingTrunc} - ${rated?.label}`} arrow>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", gap: 4}}>
                <Rating 
                    max={1}
                    readOnly
                    precision={0.1} 
                    value={parseFloat(ratingValue === 0 ? 1 : ratingValue)}
                    icon={<span>{rated?.icon}</span>}
                />
                <span>{ratingTrunc === 0 ? '(Sem Avaliação)' : ratingTrunc}</span>
            </div>
        </Tooltip>
    );
}

function User({ attendant }) {
    
    return (
        <Grid container alignItems="center">
            <Person />
            <Typography component="h3" variant="h6" color="primary">
                {attendant.name}
            </Typography>
        </Grid>
    )
}


function SupportTime({ supportTime }) {
    function formatTime(minutes) {
        return moment().startOf("day").add(minutes, "minutes").format("HH[h] mm[m]");
    }

    return (
        <>
            <Tooltip title="Tempo Médio de Atendimento" arrow>
                <Typography component="h3" variant="subtitle2" color="primary">
                    {formatTime(supportTime, 2)}
                </Typography>
            </Tooltip>
        </>
    )
}

function Tickets({ tickets }) {
    return (
        <>
            <Typography component="h3" variant="subtitle2" color="primary">
                {`${tickets} Atendimentos`}
            </Typography>
        </>
    )
}


function Queues({ queues }) {

    return (
        <>
            <Typography component="h3" variant="subtitle2" color="primary">
                Setores
            </Typography>
            <Grid
                container
                spacing={1}
                style={{ alignItems: 'flex-start', gap: 4 }}>
                {Boolean(queues?.length) &&
                    queues.map((queue, key) => (
                        <Chip
                            key={key}
                            variant="default"
                            style={{ backgroundColor: queue.color }}
                            label={queue.name}
                        />
                    ))}
            </Grid>
        </>
    )
}

export default function TableAttendantsStatus({ loading, attendants }) {
    // const classes = useStyles();

    if (loading)
        return <Skeleton variant="rect" height={150} />

    return (
        <>
            <Title>{`Usuários: ${
                attendants.length
            }`}</Title>
            <Grid container spacing={1}>
            {
                attendants.sort((a, b) => b.tickets - a.tickets).map((attendant, key) => (
                    <Grid key={key} item xs={12}>
                        <Paper style={{ padding: 16 }}>
                            <Grid container alignItems="center">
                                <Grid item xs={12} sm={6}  lg={3}><User attendant={attendant} /></Grid>
                                <Grid item xs={4}  sm={2}  lg={2}><RatingBox rating={attendant.rating} /></Grid>
                                <Grid item xs={4}  sm={2}  lg={2}><SupportTime supportTime={attendant.avgSupportTime} /></Grid>
                                <Grid item xs={4}  sm={2}  lg={2}><Tickets tickets={attendant.tickets} /></Grid>
                                <Grid item xs={12} sm={12} lg={3}><Queues queues={attendant.queues} /></Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                ))
            }
            </Grid>
        </>
    );
}
