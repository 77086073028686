import TitleWithButton from 'components/Title/TitleWithButton'
import { useEffect, useState } from 'react'
import useSettings from 'hooks/useSettings'
import {
    Grid,
    Button,
    IconButton,
    TableCell,
    TableRow,
    TableBody,
    Paper,
    Table,
    TableHead,
    Typography,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    DialogActions,
    FormControlLabel,
    Switch,
} from '@material-ui/core'
import { DeleteOutline, Edit } from '@material-ui/icons'
import toastError from 'errors/toastError'
import ConfirmationModal from 'components/ConfirmationModal'
import { useFormik } from 'formik'
import Validator from 'helpers/Validator'
import { v4 as uuid } from 'uuid'


const ApiTokenSchema = Validator.object().shape({
    description: Validator.string().required().label('Descrição'),
})


const ApiTokenModal = ({ form = {}, update, open, onClose, ...props }) => {
    const formik = useFormik({
        initialValues: {
            uuid: uuid(),
            description: '',
            active: true,
            ...form,
        },
        onSubmit: values => {
            update(values)
            handleClose()
        },
        validationSchema: ApiTokenSchema,
    })

    useEffect(() => {
        if (!open || !form || !Object.keys(form)?.length) return

        formik.setValues(form)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [form])

    const handleClose = () => {
        formik.resetForm()
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>
                {form?.name ? 'Editando' : 'Cadastrando'} Token de API
            </DialogTitle>
            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                label="Nome do Token"
                                value={formik.values.description}
                                name="description"
                                onChange={formik.handleChange}
                                error={Boolean(formik.errors?.description)}
                                helperText={formik.errors?.description}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <div style={{ wordBreak: 'break-word', }}>
                                <h3>Token da API</h3>
                                {formik.values.token}
                            </div>
                        </Grid>

                        <FormControlLabel
                            label="Ativo"
                            control={
                                <Switch
                                    checked={formik.values.active}
                                    onChange={formik.handleChange}
                                    name="active"
                                    color="primary"
                                />
                            }
                        />
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions>
                <Button onClick={handleClose}>Cancelar</Button>
                <Button onClick={formik.handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}




const ApiTokensGrid = ({ apiTokens, openUpdate, openDelete }) => {
    return (
        <Paper variant="outlined">
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell align="center">Descrição</TableCell>
                        <TableCell align="center">Token</TableCell>
                        <TableCell align="center">Ativo/Inativo</TableCell>
                        <TableCell align="center">Ações</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {apiTokens.map(apiToken => (
                        <TableRow key={apiToken.id}>
                            <TableCell align="center">{apiToken.description}</TableCell>
                            <TableCell align="center" style={{ maxWidth: 200, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }} >{apiToken.token}</TableCell>
                            <TableCell align="center">
                                {apiToken.active ? 'Ativo' : 'Inativo'}
                            </TableCell>
                            <TableCell align="center">
                                <IconButton
                                    size="small"
                                    onClick={() => openUpdate(apiToken)}>
                                    <Edit />
                                </IconButton>
                                <IconButton
                                    size="small"
                                    onClick={e => {
                                        openDelete(apiToken)
                                    }}>
                                    <DeleteOutline />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </Paper>
    )
}

const ApiTokens = ({ settings }) => {
    const { setSetting } = useSettings()
    const [modalOpen, setModalOpen] = useState(false)
    const [formUpdating, setFormUpdating] = useState()
    const [deleting, setDeleting] = useState()

    const apiTokens = settings.apiTokens ?? []

    const handleSetForm = form => {
        const idx = apiTokens.findIndex(s => s.uuid === form.uuid)

        if (idx === -1) apiTokens.push(form)
        else apiTokens[idx] = form

        setSetting('apiTokens', apiTokens)
    }

    const handleModalClose = () => {
        setFormUpdating(null)
        setModalOpen(false)
    }

    const handleDelete = () => {
        const idx = apiTokens.findIndex(s => s.uuid === deleting.uuid)
        if (idx !== -1) {
            apiTokens.splice(idx, 1)
            setSetting('apiTokens', apiTokens)
        } else {
            toastError('Este Token não foi encontrado para excluir!')
        }

        setDeleting(null)
    }

    return (
        <>
            <ConfirmationModal
                title="Excluindo Api Token"
                open={Boolean(deleting)}
                onClose={() => setDeleting(null)}
                onConfirm={() => handleDelete()}>
                Deseja realmente excluir esse Token de API?
            </ConfirmationModal>
            <ApiTokenModal
                open={modalOpen}
                form={formUpdating}
                update={handleSetForm}
                onClose={handleModalClose}
            />
            <Grid spacing={3} container>
                <TitleWithButton title="Tokens de API">
                    <Button
                        variant="contained"
                        size="small"
                        color="primary"
                        onClick={() => setModalOpen(true)}>
                        Adicionar
                    </Button>
                </TitleWithButton>

                <Grid item xs={12}>
                    <Typography variant="caption">
                        Uma API (Interface de Programação de Aplicações) é um conjunto de regras que permite que diferentes sistemas se comuniquem. Ela funciona como um intermediário entre um software e outro, facilitando a troca de dados.
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <ApiTokensGrid
                        apiTokens={apiTokens}
                        openUpdate={apiToken => {
                            setFormUpdating(apiToken)
                            setModalOpen(true)
                        }}
                        openDelete={setDeleting}
                    />
                </Grid>
            </Grid>
        </>
    )
}

export default ApiTokens
