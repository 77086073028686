import React from "react";
import DailyTimestamp from "./DailyTimestamp";
import TicketNumber from "./TicketNumber";
import { format, parseISO } from "date-fns";
import useStyles from "../useStyles";
import Divider from "./Divider";
import RejectedCall from "icons/RejectedCall";

export default function MessageCall({ ticket, currentMessage, previousMessage }) {
    const classes = useStyles()

    return (
        <>
            <DailyTimestamp {...{ currentMessage, previousMessage }} />
            <TicketNumber {...{ currentMessage, previousMessage }} />
            <Divider {...{ currentMessage, previousMessage }} />

            <div className={classes.messageCenter}>
                {ticket.isGroup && (
                    <span className={classes.messageContactName}>
                        {currentMessage.participantContact?.name}
                    </span>
                )}
                <div className={classes.messageCall}>
                    <RejectedCall
                        viewBox="0 0 20 20"
                        style={{ width: '1.25rem', height: '1.25rem' }}
                    />
                    <span>
                        Chamada de voz/vídeo perdida às{' '}
                        {format(parseISO(currentMessage.createdAt), 'HH:mm')}
                    </span>
                </div>
            </div>
        </>
    )
}
