import { useState, useEffect, useContext } from 'react'

import Validator from 'helpers/Validator'
import { Formik, Form, Field } from 'formik'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'
import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import CircularProgress from '@material-ui/core/CircularProgress'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'

import api from '../../services/api'
import toastError from '../../errors/toastError'
import QueueSelect from '../QueueSelect'
import { Can } from '../Can'
import FormikApreUser from 'components/ApreUser/FormikApreUser'
import collect from 'collect.js'
import { FormControlLabel, Switch } from '@material-ui/core'
import FormikRoleSelect from 'components/RoleSelect/FormikRoleSelect'
import { WhatsAppsContext } from 'context/WhatsApp/WhatsAppsContext'

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    multFieldLine: {
        display: 'flex',
        '& > *:not(:last-child)': {
            marginRight: theme.spacing(1),
        },
    },

    btnWrapper: {
        position: 'relative',
    },

    buttonProgress: {
        color: green[500],
        position: 'absolute',
        top: '50%',
        left: '50%',
        marginTop: -12,
        marginLeft: -12,
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}))

const UserSchema = Validator.object().shape({
    name: Validator.string().min(2).max(50).required(),
    password: Validator.string().min(5).max(50),
    email: Validator.string().email().required(),
    roleId: Validator.number().required(),
})

const UserModal = ({ open, onClose, userId, isPerfil }) => {
    const classes = useStyles()

    const initialState = {
        name: '',
        email: '',
        password: '',
        inactive: false,
        roleId: null,
    }

    const [user, setUser] = useState(initialState)
    const [selectedQueueIds, setSelectedQueueIds] = useState([])
    const [whatsappId, setWhatsappId] = useState(false)
    const { whatsApps } = useContext(WhatsAppsContext)

    useEffect(() => {
        const fetchUser = async () => {
            if (!userId) return
            try {
                const { data } = await api.get(`/users/${userId}`, {
                    include: {
                        queues: 'id,name,color',
                        company: 'id,name',
                    },
                })
                setUser(prevState => {
                    return { ...prevState, ...data }
                })
                const userQueueIds = data.queues?.map(queue => queue.id)
                setSelectedQueueIds(userQueueIds)
                setWhatsappId(data.whatsappId ? data.whatsappId : '')
            } catch (err) {
                toastError(err)
            }
        }

        if (!open) return

        fetchUser()
    }, [userId, open])

    const handleClose = () => {
        onClose()
        setUser(initialState)
    }

    const handleSaveUser = async values => {
        const userData = collect({
            ...values,
            whatsappId,
            queueIds: selectedQueueIds,
        })
            .filter(r => ![undefined, ''].includes(r))
            .all()
        try {
            if (userId) {
                await api.put(`/users/${userId}`, userData)
            } else {
                await api.post('/users', userData)
            }
            toast.success('Usuário salvo com sucesso.')
        } catch (err) {
            toastError(err)
        }
        handleClose()
    }

    return (
        <div className={classes.root}>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="xs"
                fullWidth
                scroll="paper">
                <DialogTitle id="form-dialog-title">
                    {userId ? `Editar usuário` : `Adicionar usuário`}
                </DialogTitle>
                <Formik
                    initialValues={user}
                    enableReinitialize={true}
                    validationSchema={UserSchema}
                    onSubmit={(values, actions) => {
                        setTimeout(() => {
                            handleSaveUser(values)
                            actions.setSubmitting(false)
                        }, 400)
                    }}>
                    {({ touched, errors, isSubmitting, values }) => (
                        <Form>
                            <DialogContent dividers>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        label="Nome"
                                        autoFocus
                                        name="name"
                                        error={
                                            touched.name && Boolean(errors.name)
                                        }
                                        helperText={touched.name && errors.name}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    {!isPerfil && (
                                        <Field
                                            as={FormikRoleSelect}
                                            label="Cargo"
                                            name="roleId"
                                            variant="outlined"
                                            margin="dense"
                                            fullWidth
                                            error={Boolean(errors.roleId)}
                                            helperText={errors.roleId}
                                        />
                                    )}
                                </div>
                                <div className={classes.multFieldLine}>
                                    <Field
                                        as={TextField}
                                        name="email"
                                        label="Email"
                                        error={
                                            touched.email &&
                                            Boolean(errors.email)
                                        }
                                        helperText={
                                            touched.email && errors.email
                                        }
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                    <Field
                                        as={TextField}
                                        type="password"
                                        name="password"
                                        autoComplete="new-password"
                                        label="Senha"
                                        error={Boolean(errors.password)}
                                        helperText={errors.password}
                                        variant="outlined"
                                        margin="dense"
                                        fullWidth
                                    />
                                </div>
                                <Can perform="user:update:all:department">
                                    <QueueSelect
                                        value={selectedQueueIds}
                                        onChange={values =>
                                            setSelectedQueueIds(values)
                                        }
                                    />
                                </Can>

                                <FormControl
                                    variant="outlined"
                                    margin="dense"
                                    className={classes.maxWidth}
                                    fullWidth>
                                    <InputLabel>Conexão Padrão</InputLabel>
                                    <Field
                                        as={Select}
                                        value={whatsappId}
                                        onChange={e =>
                                            setWhatsappId(e.target.value)
                                        }
                                        label="Conexão Padrão">
                                        <MenuItem value={''}>&nbsp;</MenuItem>
                                        {whatsApps.map(whatsapp => (
                                            <MenuItem
                                                key={whatsapp.id}
                                                value={whatsapp.id}>
                                                {whatsapp.name}
                                            </MenuItem>
                                        ))}
                                    </Field>
                                </FormControl>

                                <FormikApreUser name="apreId" margin="dense" />
                                <Can perform="user:update:all:inactive">
                                    <FormControl>
                                        <FormControlLabel
                                            label="Inativo"
                                            labelPlacement="end"
                                            control={
                                                <Field
                                                    as={Switch}
                                                    name="inactive"
                                                    checked={values.inactive}
                                                />
                                            }
                                        />
                                    </FormControl>
                                </Can>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    onClick={handleClose}
                                    color="secondary"
                                    disabled={isSubmitting}
                                    variant="outlined">
                                    Cancelar
                                </Button>
                                <Button
                                    type="submit"
                                    color="primary"
                                    disabled={isSubmitting}
                                    variant="contained"
                                    className={classes.btnWrapper}>
                                    {userId ? `Salvar` : `Adicionar`}
                                    {isSubmitting && (
                                        <CircularProgress
                                            size={24}
                                            className={classes.buttonProgress}
                                        />
                                    )}
                                </Button>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
        </div>
    )
}

export default UserModal
