import * as Yup from 'yup'
import { pt } from 'yup-locales'
import ptBr from '../translations/pt-br.js'

const Validator = Yup
Validator.setLocale(pt)


// Sobrescrevendo o método padrão de montagem da mensagem de erro para que seja possível ter um arquivo de tradução de nomes
const oldFormatter = Validator.ValidationError.formatError
Validator.ValidationError.formatError = (message, params) => {
    const path = params.label || ptBr[params.path] || params.path || 'this'
    params = Object.assign({}, params, {
        path,
        originalPath: params.path,
    })

    return oldFormatter(message, params)
}

export default Validator
