import MainContainer from 'components/MainContainer'
import MainHeader from 'components/MainHeader'
import Title from 'components/Title'
import toastError from 'errors/toastError'
import useAuth from 'hooks/useAuth.js'
import useSettings from 'hooks/useSettings'
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

const TestPage = () => {
    const history = useHistory()
    const { user } = useAuth()
    const { getSetting } = useSettings()

    useEffect(() => {
        // Evitando execução do useEffect caso não seja usuário super
        if (!user.super) return

        getSetting('scheduleType').then(setting => {
            debugger
        })
    }, [getSetting, user.super])

    if (!user.super) {
        toastError('Apenas super usuários podem acessar esta página')
        return history.push(`/tickets/`)
    }

    return (
        <MainContainer>
            <MainHeader>
                <Title>Teste</Title>
            </MainHeader>
        </MainContainer>
    )
}

export default TestPage
