import React, { useState, useEffect, useRef, useContext } from 'react'

import { useHistory, useParams } from 'react-router-dom'
import { parseISO, format, isSameDay } from 'date-fns'
import clsx from 'clsx'

import { makeStyles } from '@material-ui/core/styles'
import { grey } from '@material-ui/core/colors'

import api from '../../services/api'
import ButtonWithSpinner from '../ButtonWithSpinner'
import MarkdownWrapper from '../MarkdownWrapper'
import {
    IconButton,
    Tooltip,
    ListItem,
    ListItemText,
    ListItemAvatar,
    ListItemSecondaryAction,
    Typography,
    Avatar,
    Box,
    Badge,
} from '@material-ui/core'
import {
    Android,
    DoneAll,
    LinearScale,
    PeopleAlt,
    Person,
    PlayCircleFilledWhite,
    Replay,
    Schedule,
    WhatsApp,
} from '@material-ui/icons'

import { AuthContext } from '../../context/Auth/AuthContext'
import { TicketsContext } from '../../context/Tickets/TicketsContext'
import toastError from '../../errors/toastError'
import { v4 as uuidv4 } from 'uuid'

import ContactTag from '../ContactTag'

import CloseTicketModal from 'components/CloseTicketModal'
import PushPin from 'icons/PushPin'

const useStyles = makeStyles(theme => ({
    avatar: {
        width: 40,
        height: 40,
        borderRadius: '10%',
        marginRight: 5,
    },

    contactNameContainer: {
        marginRight: 15,
    },

    contactNameWrapper: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    contactLastMessage: {
        display: 'flex',
        flexDirection: 'column',

        '& > span': {
            maxWidth: '100%',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        },
    },

    tags: {
        display: 'flex',
    },

    ticketInfo: {
        display: 'flex',
        flexDirection: 'column',
        flexShrink: 0,
        alignItems: 'end',
        maxWidth: '33%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',

        '& > p': {
            display: 'flex',
            alignItems: 'center',
            lineHeight: 1.1,
            maxWidth: '100%',
        },
    },

    ticketUser: {
        fontWeight: 700,
    },

    listItemSecondaryAction: {
        right: 14,
        display: 'flex',
        flexDirection: 'column',

        '& > button': {
            maxWidth: '100%',
        },
    },

    actionPinned: {
        display: 'none',
        position: 'absolute',
        padding: 0,
        right: 0,
        top: 4,
    },
}))

const TicketListItemCustom = ({ ticket, dispatch, search }) => {
    const classes = useStyles()
    const history = useHistory()
    const [loading, setLoading] = useState(false)
    const [ticketUser, setTicketUser] = useState(null)
    const [tag, setTag] = useState([])
    const [openFinish, setOpenFinish] = useState(false)
    const [isPinned, setIsPinned] = useState(!!ticket.isPinned)

    const { ticketId } = useParams()
    const isMounted = useRef(true)
    const { setCurrentTicket, setSearchMessage } = useContext(TicketsContext)
    const { user } = useContext(AuthContext)

    useEffect(() => {
        if (ticket.userId && ticket.user) setTicketUser(ticket.user?.name)

        setTag(ticket?.tags)

        return () => {
            isMounted.current = false
        }
    }, [ticket])

    const handleReopenTicket = async id => {
        setLoading(true)
        try {
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
                queueId: ticket?.queue?.id,
                useIntegration: false,
            })

            history.push(`/tickets/${ticket.uuid}`)
        } catch (err) {
            setLoading(false)
            toastError(err)
        }
        if (isMounted.current) {
            setLoading(false)
        }
    }

    const handleAcepptTicket = async id => {
        setLoading(true)
        try {
            await api.put(`/tickets/${id}`, {
                status: 'open',
                userId: user?.id,
                useIntegration: false,
                flowId: null,
                step: null,
            })

            let settingIndex

            if (!ticket.isGroup) {
                try {
                    const { data } = await api.get('/settings', {
                        key: 'sendGreetingAccepted',
                    })

                    settingIndex = data
                } catch (err) {
                    toastError(err)
                }

                if (settingIndex) handleSendMessage(ticket.id)
            }

            history.push(`/tickets/${ticket.uuid}`)
        } catch (err) {
            setLoading(false)

            toastError(err)
        }
        if (isMounted.current) {
            setLoading(false)
        }
    }

    const handleSendMessage = async id => {
        const msg = `{{ms}} *{{name}}*, meu nome é *${user?.name}* e agora vou prosseguir com seu atendimento!`
        const message = {
            read: 1,
            fromMe: true,
            mediaPath: '',
            body: `*Mensagem Automática:*\n${msg.trim()}`,
        }
        try {
            await api.post(`/messages/${id}`, message)
        } catch (err) {
            toastError(err)
        }
    }

    const handleSelectTicket = ticket => {
        const code = uuidv4()
        const { id, uuid } = ticket
        setCurrentTicket({ id, uuid, code })
        setSearchMessage(search)
    }

    const handlePinTicket = ticket => {
        setIsPinned(prev => {
            ticket.isPinned = !prev
            api.post(`/tickets/${ticket.id}/pin`, { pinned: ticket.isPinned })
            dispatch({ type: 'UPDATE_TICKET', payload: ticket })
            return ticket.isPinned
        })
    }

    const renderTicketInfo = () => {
        return (
            <>
                {ticket.chatbot && (
                    <Tooltip title="Chatbot">
                        <Android
                            fontSize="small"
                            style={{ color: grey[700], marginRight: 5 }}
                        />
                    </Tooltip>
                )}
            </>
        )
    }

    const renderLastMessage = () => {
        if (ticket.lastMessage?.includes('data:image/png;base64'))
            return <MarkdownWrapper>Localização</MarkdownWrapper>

        if (ticket.lastMessage?.includes('BEGIN:VCARD'))
            return <MarkdownWrapper>Contato</MarkdownWrapper>

        return <MarkdownWrapper>{ticket.lastMessage}</MarkdownWrapper>
    }

    return (
        <ListItem
            key={ticket.id}
            dense
            button
            divider
            onClick={() => {
                handleSelectTicket(ticket)
            }}
            selected={ticketId === ticket.uuid}
            className={clsx({
                [classes.pendingTicket]: ticket.status === 'pending',
            })}>
            <CloseTicketModal
                ticket={ticket}
                open={openFinish}
                onClose={() => setOpenFinish(false)}
            />
            <ListItemAvatar>
                <Badge
                    overlap="rectangular"
                    badgeContent={
                        ticket.unreadMessages > 99
                            ? '99+'
                            : ticket.unreadMessages
                    }
                    color="secondary"
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}>
                    <Avatar
                        classes={{ root: classes.avatar }}
                        src={ticket?.contact?.profilePicUrl}
                    />
                </Badge>
            </ListItemAvatar>
            <ListItemText
                disableTypography
                classes={{ root: classes.contactNameContainer }}
                primary={
                    <span className={classes.contactNameWrapper}>
                        <Typography
                            noWrap
                            component="span"
                            variant="body2"
                            color="textPrimary">
                            {ticket.contact.name}
                        </Typography>
                        <ListItemSecondaryAction>
                            <Box>{renderTicketInfo()}</Box>
                        </ListItemSecondaryAction>
                    </span>
                }
                secondary={
                    <Typography
                        className={classes.contactLastMessage}
                        noWrap
                        component="span"
                        variant="body2"
                        color="textSecondary">
                        {renderLastMessage()}

                        {!!tag?.length && (
                            <span
                                style={{ paddingTop: '2px' }}
                                className={classes.tags}>
                                {tag?.map(tag => {
                                    return (
                                        <ContactTag
                                            tag={tag}
                                            key={`ticket-contact-tag-${ticket.id}-${tag.id}`}
                                        />
                                    )
                                })}
                            </span>
                        )}
                    </Typography>
                }
            />

            <div className={classes.ticketInfo}>
                {Boolean(ticket.isGroup) && (
                    <>
                        <IconButton
                            size="small"
                            className={`${classes.actionPinned} ${
                                isPinned ? 'isPinned' : ''
                            }`}
                            onClick={e => {
                                e.stopPropagation()
                                handlePinTicket(ticket)
                            }}>
                            <PushPin style={{ fontSize: '1rem' }} />
                        </IconButton>

                        {ticket.status === 'bot' && (
                            <Typography variant="body2" color="textSecondary">
                                <LinearScale fontSize="inherit" />
                                <span>No Bot</span>
                            </Typography>
                        )}
                    </>
                )}

                <Typography variant="body2" color="textSecondary">
                    <Schedule fontSize="inherit" />
                    <span>
                        {isSameDay(parseISO(ticket.updatedAt), new Date()) ? (
                            <>{format(parseISO(ticket.updatedAt), 'HH:mm')}</>
                        ) : (
                            <>
                                {format(
                                    parseISO(ticket.updatedAt),
                                    'dd/MM/yyyy',
                                )}
                            </>
                        )}
                    </span>
                </Typography>

                {ticketUser && (
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        noWrap
                        classes={{ root: classes.ticketUser }}>
                        <Person fontSize="inherit" />
                        <span>{ticketUser}</span>
                    </Typography>
                )}

                {ticket.queueId && (
                    <Typography variant="body2" color="textSecondary" noWrap>
                        <PeopleAlt
                            fontSize="inherit"
                            style={{
                                color: ticket.queue?.color || 'inherit',
                            }}
                        />
                        <span>{ticket.queue?.name}</span>
                    </Typography>
                )}

                <Typography variant="body2" color="textSecondary" noWrap>
                    <WhatsApp fontSize="inherit" style={{ color: '#25d366' }} />
                    <span>{ticket?.whatsapp?.name}</span>
                </Typography>
            </div>

            <ListItemSecondaryAction
                className={classes.listItemSecondaryAction}>
                {['pending', 'bot'].includes(ticket.status) && (
                    <ButtonWithSpinner
                        component={IconButton}
                        style={{
                            color: 'green',
                        }}
                        size="small"
                        loading={loading}
                        onClick={e => {
                            e.stopPropagation()
                            handleAcepptTicket(ticket.id)
                        }}>
                        <Tooltip title="Aceitar">
                            <PlayCircleFilledWhite />
                        </Tooltip>
                    </ButtonWithSpinner>
                )}
                {ticket.status !== 'closed' && (
                    <ButtonWithSpinner
                        component={IconButton}
                        style={{
                            color: 'red',
                        }}
                        size="small"
                        loading={loading}
                        onClick={e => {
                            e.stopPropagation()
                            setOpenFinish(true)
                        }}>
                        <Tooltip title="Finalizar">
                            <DoneAll />
                        </Tooltip>
                    </ButtonWithSpinner>
                )}
                {ticket.status === 'closed' && (
                    <ButtonWithSpinner
                        component={IconButton}
                        style={{
                            color: 'red',
                        }}
                        size="small"
                        loading={loading}
                        onClick={e => {
                            e.stopPropagation()
                            handleReopenTicket(ticket.id)
                        }}>
                        <Tooltip title="Reabrir">
                            <Replay />
                        </Tooltip>
                    </ButtonWithSpinner>
                )}
            </ListItemSecondaryAction>
        </ListItem>
    )
}

export default TicketListItemCustom
