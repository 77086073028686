import React, { useState, useEffect, useReducer, useContext } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'
import { Can } from 'components/Can'
import {
    AddCircleOutline,
    DeleteOutline,
    Edit,
    FileCopy,
    PauseCircleFilled,
    PlayCircleFilled,
    Search,
} from '@material-ui/icons'
import { Grid } from '@material-ui/core'
import TransmissionModal from 'components/TransmissionModal'

const reducer = (state, action) => {
    if (action.type === 'LOAD_TRANSMISSIONS') {
        const transmissions = action.payload
        const newTransmissions = []

        transmissions.forEach(transmission => {
            const index = state.findIndex(u => u.id === transmission.id)
            if (index !== -1) {
                state[index] = transmission
            } else {
                newTransmissions.push(transmission)
            }
        })

        return [...state, ...newTransmissions]
    }

    if (action.type === 'UPDATE_TRANSMISSIONS') {
        const transmission = action.payload
        const index = state.findIndex(u => u.id === transmission.id)

        if (index !== -1) {
            state[index] = transmission
            return [...state]
        } else {
            return [transmission, ...state]
        }
    }

    if (action.type === 'DELETE_TRANSMISSION') {
        const transmissionId = action.payload

        const index = state.findIndex(u => u.id === transmissionId)
        if (index !== -1) {
            state.splice(index, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const statusTranslate = {
    pending: 'Pendente',
    stopped: 'Pausado',
    finished: 'Finalizado',
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const Transmission = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedTransmission, setSelectedTransmission] = useState(null)
    const [deletingTransmission, setDeletingTransmission] = useState(null)
    const [modalOpen, setModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [transmissions, dispatch] = useReducer(reducer, [])

    const [filters, setFilters] = useState({
        smart: '',
    })

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [filters])

    useEffect(() => {
        setLoading(true)

        const delayDebounceFn = setTimeout(() => {
            const fetchData = async () => {
                try {
                    const { data } = await api.get('/transmission/', {
                        filters: filters,
                        include: {
                            transmissionList: 'status',
                        },
                        page: pageNumber,
                    })
                    dispatch({ type: 'LOAD_TRANSMISSIONS', payload: data.data })
                    setHasMore(transmissions.length < data.total)
                    setLoading(false)
                } catch (err) {
                    toastError(err)
                }
            }
            fetchData()
        }, 500)
        return () => clearTimeout(delayDebounceFn)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, pageNumber])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socket.on(`transmission`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({
                    type: 'UPDATE_TRANSMISSIONS',
                    payload: data.transmission,
                })
            }

            if (data.action === 'delete') {
                dispatch({
                    type: 'DELETE_TRANSMISSION',
                    payload: +data.transmissionId,
                })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [socketManager])

    const handleOpenModal = () => {
        setSelectedTransmission(null)
        setModalOpen(true)
    }

    const handleCloseModal = () => {
        setSelectedTransmission(null)
        setModalOpen(false)
    }

    const handleSearch = (name, value) => {
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleReplicate = transmission => {
        api.post(`/transmission/${transmission.id}/replicate`)
            .then(() => {
                toast.success('Transmissão duplicada com sucesso.')
            })
            .catch(err => {
                toastError(err)
            })
    }

    const handleStatus = (transmission, status) => {
        api.put(`/transmission/${transmission.id}`, { status })
            .then(() => {
                toast.success('Transmissão alterada com sucesso.')
            })
            .catch(err => {
                toastError(err)
            })
    }

    const handleEdit = transmission => {
        setSelectedTransmission(transmission)
        setModalOpen(true)
    }

    const handleDelete = async transmissionId => {
        try {
            await api.delete(`/transmission/${transmissionId}`)
            toast.success('Transmissão excluída com sucesso.')
        } catch (err) {
            toastError(err)
        }
        setDeletingTransmission(null)
        setFilters({
            smart: '',
        })
        setPageNumber(1)
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    if (
        !canOrReturn(
            [
                'transmission:page',
                'transmission:create',
                'transmission:update',
                'transmission:delete',
            ],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingTransmission &&
                    `Excluir ${deletingTransmission.name}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDelete(deletingTransmission.id)}>
                Todos os dados da transmissão serão perdidos. Tens certeza
                disso?
            </ConfirmationModal>
            <TransmissionModal
                open={modalOpen}
                onClose={handleCloseModal}
                transmissionId={selectedTransmission && selectedTransmission.id}
                handleDelete={() => {
                    setConfirmModalOpen(true)
                    setDeletingTransmission(selectedTransmission)
                    handleCloseModal()
                }}
            />
            <MainHeader>
                <Grid container alignItems="flex-start">
                    <Grid container justifyContent="space-between">
                        <Title>Lista de Transmissões</Title>
                        <Button
                            size="small"
                            variant="contained"
                            color="primary"
                            onClick={handleOpenModal}>
                            <Grid
                                container
                                alignItems="center"
                                style={{ gap: 4 }}>
                                <AddCircleOutline />
                                Adicionar
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid
                        container
                        justifyContent="flex-end"
                        alignItems="center"
                        style={{ gap: 4 }}>
                        <TextField
                            placeholder={'Pesquisar...'}
                            type="search"
                            value={filters.smart}
                            onChange={e =>
                                handleSearch(
                                    'smart',
                                    e.target.value.toLowerCase(),
                                )
                            }
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search style={{ color: 'gray' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Nome</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Enviados</TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {transmissions.map(transmission => (
                                <TableRow key={transmission.id}>
                                    <TableCell align="center">
                                        {transmission.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {statusTranslate[transmission.status]}
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            transmission.transmissionList.filter(
                                                t => t.status === 'sent',
                                            ).length
                                        }
                                        /{transmission.transmissionList.length}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Can perform="transmission:create">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleReplicate(
                                                        transmission,
                                                    )
                                                }>
                                                <FileCopy />
                                            </IconButton>
                                        </Can>

                                        <Can perform="transmission:update">
                                            {transmission.status ===
                                                'stopped' && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleStatus(
                                                            transmission,
                                                            'pending',
                                                        )
                                                    }>
                                                    <PlayCircleFilled />
                                                </IconButton>
                                            )}

                                            {transmission.status ===
                                                'pending' && (
                                                <IconButton
                                                    size="small"
                                                    onClick={() =>
                                                        handleStatus(
                                                            transmission,
                                                            'stopped',
                                                        )
                                                    }>
                                                    <PauseCircleFilled />
                                                </IconButton>
                                            )}

                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleEdit(transmission)
                                                }>
                                                <Edit />
                                            </IconButton>
                                        </Can>

                                        <Can perform="transmission:delete">
                                            <IconButton
                                                size="small"
                                                onClick={e => {
                                                    setConfirmModalOpen(true)
                                                    setDeletingTransmission(
                                                        transmission,
                                                    )
                                                }}>
                                                <DeleteOutline />
                                            </IconButton>
                                        </Can>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Transmission
