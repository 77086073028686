import React, { useState, useEffect, useReducer, useContext } from 'react'
import { toast } from 'react-toastify'

import { makeStyles } from '@material-ui/core/styles'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import MainContainer from '../../components/MainContainer'
import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import UserModal from '../../components/UserModal'
import ConfirmationModal from '../../components/ConfirmationModal'
import toastError from '../../errors/toastError'
import { SocketContext } from '../../context/Socket/SocketContext'
import useCan from 'hooks/useCan'
import { Can } from 'components/Can'
import { AddCircleOutline, Block, CheckCircle } from '@material-ui/icons'
import QueueSelectCustom from 'components/QueueSelectCustom'
import { Grid } from '@material-ui/core'

const reducer = (state, action) => {
    if (action.type === 'LOAD_USERS') {
        const users = action.payload
        const newUsers = []

        users.forEach(user => {
            const userIndex = state.findIndex(u => u.id === user.id)
            if (userIndex !== -1) {
                state[userIndex] = user
            } else {
                newUsers.push(user)
            }
        })

        return [...state, ...newUsers]
    }

    if (action.type === 'UPDATE_USERS') {
        const user = action.payload
        const userIndex = state.findIndex(u => u.id === user.id)

        if (userIndex !== -1) {
            state[userIndex] = user
            return [...state]
        } else {
            return [user, ...state]
        }
    }

    if (action.type === 'DELETE_USER') {
        const userId = action.payload

        const userIndex = state.findIndex(u => u.id === userId)
        if (userIndex !== -1) {
            state.splice(userIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const Users = () => {
    const classes = useStyles()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [hasMore, setHasMore] = useState(false)
    const [selectedUser, setSelectedUser] = useState(null)
    const [deletingUser, setDeletingUser] = useState(null)
    const [userModalOpen, setUserModalOpen] = useState(false)
    const [confirmModalOpen, setConfirmModalOpen] = useState(false)
    const [users, dispatch] = useReducer(reducer, [])

    const [companyId, setCompanyId] = useState('')
    const [filters, setFilters] = useState({
        smart: '',
        queueIn: [],
    })

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [filters])

    useEffect(() => {
        setLoading(true)

        const delayDebounceFn = setTimeout(() => {
            const fetchUsers = async () => {
                try {
                    const { data } = await api.get('/users/', {
                        fields: 'id,name,email,inactive,companyId,roleId,createdAt',
                        filters: filters,
                        include: {
                            queues: 'id,name,colod',
                            company: 'id,name',
                            role: 'id,name',
                        },
                        page: pageNumber,
                    })
                    dispatch({ type: 'LOAD_USERS', payload: data.data })
                    setHasMore(users.length < data.total)
                    setLoading(false)
                } catch (err) {
                    toastError(err)
                }
            }
            fetchUsers()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, pageNumber])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)
        setCompanyId(companyId)

        socket.on(`company-${companyId}-user`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_USERS', payload: data.user })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_USER', payload: +data.userId })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [socketManager])

    const handleOpenUserModal = () => {
        setSelectedUser(null)
        setUserModalOpen(true)
    }

    const handleCloseUserModal = () => {
        setSelectedUser(null)
        setUserModalOpen(false)
    }

    const handleSearch = (name, value) => {
        setFilters(prev => ({
            ...prev,
            [name]: value,
        }))
    }

    const handleEditUser = user => {
        setSelectedUser(user)
        setUserModalOpen(true)
    }

    const handleDeleteUser = async userId => {
        try {
            await api.delete(`/users/${userId}`)
            toast.success('Usuário excluído com sucesso.')
        } catch (err) {
            toastError(err)
        }
        setDeletingUser(null)
        setFilters({
            smart: '',
            queueIn: [],
        })
        setPageNumber(1)
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    if (
        !canOrReturn(
            ['user:page', 'user:create', 'user:update:all', 'user:delete:all'],
            '/tickets',
        )
    )
        return null

    return (
        <MainContainer>
            <ConfirmationModal
                title={
                    deletingUser &&
                    `Excluir ${deletingUser.name}?`
                }
                open={confirmModalOpen}
                onClose={setConfirmModalOpen}
                onConfirm={() => handleDeleteUser(deletingUser.id)}>
                Todos os dados do usuário serão perdidos. Os atendimento abertos deste usuário serão movidos para o setor.
            </ConfirmationModal>
            <UserModal
                open={userModalOpen}
                onClose={handleCloseUserModal}
                aria-labelledby="form-dialog-title"
                userId={selectedUser && selectedUser.id}
            />
            <MainHeader>
                <Grid container alignItems="flex-start" >
                    <Grid container justifyContent='space-between' >
                        <Title>Usuários</Title>
                        <Button
                            size='small'
                            variant="contained"
                            color="primary"
                            onClick={handleOpenUserModal}
                        >
                            <Grid container alignItems="center" style={{ gap: 4 }}>
                                <AddCircleOutline />
                                Adicionar
                            </Grid>
                        </Button>
                    </Grid>
                    <Grid container justifyContent="flex-end" alignItems='center' style={{ gap: 4 }}>
                        <TextField
                            placeholder={'Pesquisar...'}
                            type="search"
                            value={filters.smart}
                            onChange={e => handleSearch('smart', e.target.value.toLowerCase())}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <SearchIcon style={{ color: 'gray' }} />
                                    </InputAdornment>
                                ),
                            }}
                        />
                        {Boolean(companyId) &&
                            <QueueSelectCustom
                                chip={false}
                                companyId={companyId}
                                style={{ maxWidth: '24rem' }}
                                className="MuiFormControl-root"
                                selectedQueueIds={filters.queueIn}
                                onChange={values =>
                                    handleSearch('queueIn', values)
                                }
                            />}
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                ID
                            </TableCell>
                            <TableCell align="center">
                                Nome
                            </TableCell>
                            <TableCell align="center">
                                Email
                            </TableCell>
                            <TableCell align="center">
                                Cargo
                            </TableCell>
                            <TableCell align="center">
                                Status
                            </TableCell>
                            <TableCell align="center">
                                Ações
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {users.map(user => (
                                <TableRow key={user.id}>
                                    <TableCell align="left">
                                        {user.id}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.role.name}
                                    </TableCell>
                                    <TableCell align="center">
                                        {user.inactive ? <Block htmlColor='red' /> : <CheckCircle htmlColor='green' />}
                                    </TableCell>
                                    <TableCell align="center">
                                        <Can perform="user:update:all">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    handleEditUser(user)
                                                }>
                                                <EditIcon />
                                            </IconButton>
                                        </Can>

                                        <Can perform="user:delete:all">
                                            <IconButton
                                                size="small"
                                                onClick={e => {
                                                    setConfirmModalOpen(true)
                                                    setDeletingUser(user)
                                                }}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Can>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton columns={4} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Users
