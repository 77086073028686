import React, { useState, useEffect, useReducer, useContext } from 'react'

import { toast } from 'react-toastify'
import { useHistory } from 'react-router-dom'

import { makeStyles } from '@material-ui/core/styles'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Avatar from '@material-ui/core/Avatar'
import WhatsAppIcon from '@material-ui/icons/WhatsApp'
import SearchIcon from '@material-ui/icons/Search'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'

import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import EditIcon from '@material-ui/icons/Edit'

import api from '../../services/api'
import TableRowSkeleton from '../../components/TableRowSkeleton'
import ContactModal from '../../components/ContactModal'
import ConfirmationModal from '../../components/ConfirmationModal/'

import MainHeader from '../../components/MainHeader'
import Title from '../../components/Title'
import MainContainer from '../../components/MainContainer'
import toastError from '../../errors/toastError'
import { Can } from '../../components/Can'
import NewTicketModal from '../../components/NewTicketModal'
import { SocketContext } from '../../context/Socket/SocketContext'
import {
    Checkbox,
    FormControlLabel,
    Grid,
    ListItemText,
    MenuItem,
    Select,
    Typography,
} from '@material-ui/core'

import { CSVLink } from 'react-csv'
import useCan from 'hooks/useCan'
import useAuth from 'hooks/useAuth.js'
import { AddCircleOutline, Share, Smartphone } from '@material-ui/icons'
import QueueSelectCustom from 'components/QueueSelectCustom'
import UserSelect from 'components/UserSelect'

const reducer = (state, action) => {
    if (action.type === 'LOAD_CONTACTS') {
        const contacts = action.payload
        const newContacts = []

        contacts.forEach(contact => {
            const contactIndex = state.findIndex(c => c.id === contact.id)
            if (contactIndex !== -1) {
                state[contactIndex] = contact
            } else {
                newContacts.push(contact)
            }
        })

        return [...state, ...newContacts]
    }

    if (action.type === 'UPDATE_CONTACTS') {
        const contact = action.payload
        const contactIndex = state.findIndex(c => c.id === contact.id)

        if (contactIndex !== -1) {
            state[contactIndex] = contact
            return [...state]
        } else {
            return [contact, ...state]
        }
    }

    if (action.type === 'DELETE_CONTACT') {
        const contactId = action.payload

        const contactIndex = state.findIndex(c => c.id === contactId)
        if (contactIndex !== -1) {
            state.splice(contactIndex, 1)
        }
        return [...state]
    }

    if (action.type === 'RESET') {
        return []
    }
}

const useStyles = makeStyles(theme => ({
    mainPaper: {
        flex: 1,
        padding: theme.spacing(1),
        overflowY: 'scroll',
        ...theme.scrollbarStyles,
    },
}))

const Contacts = () => {
    const classes = useStyles()
    const history = useHistory()
    const socketManager = useContext(SocketContext)
    const { canOrReturn } = useCan()

    const [loading, setLoading] = useState(false)
    const [pageNumber, setPageNumber] = useState(1)
    const [contacts, dispatch] = useReducer(reducer, [])
    const [selectedContactId, setSelectedContactId] = useState(null)
    const [contactModalOpen, setContactModalOpen] = useState(false)
    const [newTicketModalOpen, setNewTicketModalOpen] = useState(false)
    const [contactTicket, setContactTicket] = useState({})
    const [deletingContact, setDeletingContact] = useState(null)
    const [confirmOpen, setConfirmOpen] = useState(false)
    const [hasMore, setHasMore] = useState(false)

    const [filters, setFilters] = useState({
        smart: '',
        queueIn: [],
        userIn: [],
    })

    const { user } = useAuth()
    const { companyId, whatsappId } = user
    const [selectedWhatsapp, setSelectedWhatsapp] = useState(null)
    const [whatsapps, setWhatsapps] = useState([])

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            const fetchConnections = async () => {
                api.get(`/whatsapp`, { companyId, session: 0 }).then(
                    ({ data }) => setWhatsapps(data),
                )
            }

            if (whatsappId !== null && whatsappId !== undefined)
                setSelectedWhatsapp(whatsappId)

            fetchConnections()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
    }, [companyId, whatsappId])

    useEffect(() => {
        dispatch({ type: 'RESET' })
        setPageNumber(1)
    }, [filters])

    useEffect(() => {
        setLoading(true)
        const delayDebounceFn = setTimeout(() => {
            const fetchContacts = async () => {
                try {
                    const { data } = await api.get('/contacts/', {
                        filters: filters,
                        page: pageNumber,
                    })
                    dispatch({ type: 'LOAD_CONTACTS', payload: data.data })
                    setHasMore(contacts.length < data.total)
                    setLoading(false)
                } catch (err) {
                    toastError(err)
                }
            }
            fetchContacts()
        }, 500)
        return () => clearTimeout(delayDebounceFn)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, pageNumber])

    useEffect(() => {
        const companyId = localStorage.getItem('companyId')
        const socket = socketManager.GetSocket(companyId)

        socket.on(`company-${companyId}-contact`, data => {
            if (data.action === 'update' || data.action === 'create') {
                dispatch({ type: 'UPDATE_CONTACTS', payload: data.contact })
            }

            if (data.action === 'delete') {
                dispatch({ type: 'DELETE_CONTACT', payload: +data.contactId })
            }
        })

        return () => {
            socket.disconnect()
        }
    }, [socketManager])

    const handleOpenContactModal = () => {
        setSelectedContactId(null)
        setContactModalOpen(true)
    }

    const handleCloseContactModal = () => {
        setSelectedContactId(null)
        setContactModalOpen(false)
    }

    const handleCloseOrOpenTicket = ticket => {
        setNewTicketModalOpen(false)
        if (ticket !== undefined && ticket.uuid !== undefined) {
            history.push(`/tickets/${ticket.uuid}`)
        }
    }

    const hadleEditContact = contactId => {
        setSelectedContactId(contactId)
        setContactModalOpen(true)
    }

    const handleDeleteContact = async contactId => {
        try {
            await api.delete(`/contacts/${contactId}`)
            toast.success('Contato excluído com sucesso!')
        } catch (err) {
            toastError(err)
        }
        setDeletingContact(null)
        setFilters({
            smart: '',
            queueIn: [],
            userIn: [],
        })

        setPageNumber(1)
    }

    const handleimportContact = async () => {
        try {
            await api.post('/contacts/import', { whatsappId: selectedWhatsapp })
            history.go(0)
        } catch (err) {
            toastError(err)
        }
    }

    const handleSearch = (name, value, remove = false) => {
        setFilters(prev => {
            if (remove) delete prev[name]
            else prev[name] = value
            return { ...prev }
        })
    }

    const loadMore = () => {
        setPageNumber(prevState => prevState + 1)
    }

    const handleScroll = e => {
        if (!hasMore || loading) return
        const { scrollTop, scrollHeight, clientHeight } = e.currentTarget
        if (scrollHeight - (scrollTop + 100) < clientHeight) {
            loadMore()
        }
    }

    if (!canOrReturn(['contact:page'], '/tickets')) return null

    return (
        <MainContainer className={classes.mainContainer}>
            <NewTicketModal
                modalOpen={newTicketModalOpen}
                initialContact={contactTicket}
                onClose={ticket => {
                    handleCloseOrOpenTicket(ticket)
                }}
            />
            <ContactModal
                open={contactModalOpen}
                onClose={handleCloseContactModal}
                aria-labelledby="form-dialog-title"
                contactId={selectedContactId}></ContactModal>
            <ConfirmationModal
                title={
                    deletingContact
                        ? `Deletar ${deletingContact.name}?`
                        : `Importar contatos`
                }
                open={confirmOpen}
                onClose={() => {
                    setConfirmOpen(false)
                    deletingContact && setDeletingContact(null)
                    selectedWhatsapp && setSelectedWhatsapp(null)
                }}
                onConfirm={e =>
                    deletingContact
                        ? handleDeleteContact(deletingContact.id)
                        : handleimportContact()
                }>
                {confirmOpen
                    ? deletingContact
                        ? `Tem certeza que deseja deletar este contato? Todos os atendimentos relacionados serão perdidos.`
                        : `Selecione uma conexão para importar todos os contatos do telefone:`
                    : null}
                {confirmOpen && !deletingContact && (
                    <Select
                        required
                        fullWidth
                        displayEmpty
                        variant="outlined"
                        value={selectedWhatsapp}
                        onChange={e => setSelectedWhatsapp(e.target.value)}
                        MenuProps={{
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'left',
                            },
                            transformOrigin: {
                                vertical: 'top',
                                horizontal: 'left',
                            },
                            getContentAnchorEl: null,
                        }}
                        renderValue={() => {
                            if (!selectedWhatsapp) return 'Conexão Padrão'

                            const whatsapp = whatsapps.find(
                                w => w.id === selectedWhatsapp,
                            )
                            return whatsapp.name
                        }}>
                        {whatsapps?.length > 0 &&
                            whatsapps.map((whatsapp, key) => (
                                <MenuItem dense key={key} value={whatsapp.id}>
                                    <ListItemText
                                        primary={
                                            <>
                                                <Typography
                                                    component="span"
                                                    style={{
                                                        fontSize: 14,
                                                        marginLeft: '10px',
                                                        display: 'inline-flex',
                                                        alignItems: 'center',
                                                        lineHeight: '2',
                                                    }}>
                                                    {whatsapp.name} &nbsp;{' '}
                                                    <p
                                                        className={
                                                            whatsapp.status ===
                                                            'CONNECTED'
                                                                ? classes.online
                                                                : classes.offline
                                                        }>
                                                        {' '}
                                                        ({whatsapp.status}){' '}
                                                    </p>
                                                </Typography>
                                            </>
                                        }
                                    />
                                </MenuItem>
                            ))}
                    </Select>
                )}
            </ConfirmationModal>
            <MainHeader>
                <Grid
                    container
                    alignItems="flex-start"
                    justifyContent="space-between">
                    <Grid item xs="auto">
                        <Title>Contatos</Title>
                    </Grid>
                    <Grid
                        item
                        xs="auto"
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 8,
                        }}>
                        <Grid
                            container
                            justifyContent="flex-end"
                            style={{ gap: 4 }}>
                            <Can perform="contact:create">
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    onClick={handleOpenContactModal}
                                    title={'Adicionar'}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{ gap: 4 }}>
                                        <AddCircleOutline />
                                        {'Adicionar'}
                                    </Grid>
                                </Button>
                            </Can>

                            <Button
                                variant="contained"
                                color="primary"
                                size="small"
                                onClick={e => setConfirmOpen(true)}
                                title={'Importar'}>
                                <Grid
                                    container
                                    alignItems="center"
                                    style={{ gap: 4 }}>
                                    <Smartphone />
                                    Importar
                                </Grid>
                            </Button>

                            <CSVLink
                                style={{ textDecoration: 'none' }}
                                separator=";"
                                filename={'contatos.csv'}
                                data={contacts.map(contact => ({
                                    name: contact.name,
                                    number: contact.number,
                                    email: contact.email,
                                }))}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="small"
                                    title={'Exportar'}>
                                    <Grid
                                        container
                                        alignItems="center"
                                        style={{ gap: 4 }}>
                                        <Share />
                                        Exportar
                                    </Grid>
                                </Button>
                            </CSVLink>
                        </Grid>
                        <Grid
                            container
                            justifyContent="flex-end"
                            alignItems="center"
                            style={{ gap: 4 }}>
                            <FormControlLabel
                                label="Grupos"
                                control={
                                    <Checkbox
                                        size="small"
                                        color="primary"
                                        checked={filters.isGroup}
                                        onChange={e =>
                                            handleSearch(
                                                'isGroup',
                                                e.target.checked,
                                                !e.target.checked,
                                            )
                                        }
                                    />
                                }
                            />
                            <TextField
                                placeholder={'Pesquisar...'}
                                type="search"
                                value={filters.smart}
                                onChange={e =>
                                    handleSearch(
                                        'smart',
                                        e.target.value.toLowerCase(),
                                    )
                                }
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon
                                                style={{ color: 'gray' }}
                                            />
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            <UserSelect
                                multiple
                                value={filters.userIn}
                                style={{ maxWidth: '24rem' }}
                                onChange={values =>
                                    handleSearch('userIn', values)
                                }
                            />
                            {Boolean(companyId) && (
                                <QueueSelectCustom
                                    chip={false}
                                    companyId={companyId}
                                    style={{ maxWidth: '24rem' }}
                                    className="MuiFormControl-root"
                                    selectedQueueIds={filters.queueIn}
                                    onChange={values =>
                                        handleSearch('queueIn', values)
                                    }
                                />
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </MainHeader>
            <Paper
                className={classes.mainPaper}
                variant="outlined"
                onScroll={handleScroll}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox" />
                            <TableCell>Nome</TableCell>
                            <TableCell align="center">Whatsapp</TableCell>
                            <TableCell align="center">Email</TableCell>
                            <TableCell align="center">Ações</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        <>
                            {contacts.map(contact => (
                                <TableRow key={contact.id}>
                                    <TableCell style={{ paddingRight: 0 }}>
                                        {<Avatar src={contact.profilePicUrl} />}
                                    </TableCell>
                                    <TableCell>{contact.name}</TableCell>
                                    <TableCell align="center">
                                        {contact.number}
                                    </TableCell>
                                    <TableCell align="center">
                                        {contact.email}
                                    </TableCell>
                                    <TableCell align="center">
                                        <IconButton
                                            size="small"
                                            onClick={() => {
                                                setContactTicket(contact)
                                                setNewTicketModalOpen(true)
                                            }}>
                                            <WhatsAppIcon />
                                        </IconButton>
                                        <Can perform="contact:update">
                                            <IconButton
                                                size="small"
                                                onClick={() =>
                                                    hadleEditContact(contact.id)
                                                }>
                                                <EditIcon />
                                            </IconButton>
                                        </Can>
                                        <Can perform="contact:delete:all">
                                            <IconButton
                                                size="small"
                                                onClick={e => {
                                                    setConfirmOpen(true)
                                                    setDeletingContact(contact)
                                                }}>
                                                <DeleteOutlineIcon />
                                            </IconButton>
                                        </Can>
                                    </TableCell>
                                </TableRow>
                            ))}
                            {loading && <TableRowSkeleton avatar columns={3} />}
                        </>
                    </TableBody>
                </Table>
            </Paper>
        </MainContainer>
    )
}

export default Contacts
