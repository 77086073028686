import React, { useState, createContext, useEffect } from 'react'

const MessageContext = createContext()

const MessageProvider = ({ children }) => {
    const [message, setMessage] = useState(null)
    const [type, setType] = useState(null)

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === "Escape" && type) {
                setType(null);
                setMessage(null);
                event.stopPropagation();
            }
        };

        document.addEventListener("keydown", handleKeyDown);
        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [type]);

    const setReplyingMessage = newMessage => {
        if (!newMessage) setType(null)
        else setType('reply')
        
        setMessage(newMessage)
    }

    const setEditingMessage = newMessage => {
        if (!newMessage) setType(null)
        else setType('edit')

        setMessage(newMessage)
    }

    return (
        <MessageContext.Provider value={{ message, setMessage, type, setType, setReplyingMessage, setEditingMessage }}>
            {children}
        </MessageContext.Provider>
    )
}

export { MessageContext, MessageProvider }
