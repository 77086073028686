import React, { useEffect, useState } from 'react'

import Grid from '@material-ui/core/Grid'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import useSettings from '../../hooks/useSettings'
import { toast } from 'react-toastify'
import { makeStyles } from '@material-ui/core/styles'
import { grey, blue } from '@material-ui/core/colors'

const useStyles = makeStyles(theme => ({
    container: {
        paddingTop: theme.spacing(4),
        paddingBottom: theme.spacing(4),
    },
    fixedHeightPaper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
        height: 240,
    },
    tab: {
        backgroundColor: theme.palette.options,
        borderRadius: 4,
        width: '100%',
        '& .MuiTab-wrapper': {
            color: theme.palette.fontecor,
        },
        '& .MuiTabs-flexContainer': {
            justifyContent: 'center',
        },
    },
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        alignItems: 'center',
        marginBottom: 12,
        width: '100%',
    },
    cardAvatar: {
        fontSize: '55px',
        color: grey[500],
        backgroundColor: '#ffffff',
        width: theme.spacing(7),
        height: theme.spacing(7),
    },
    cardTitle: {
        fontSize: '18px',
        color: blue[700],
    },
    cardSubtitle: {
        color: grey[600],
        fontSize: '14px',
    },
    alignRight: {
        textAlign: 'right',
    },
    fullWidth: {
        width: '100%',
    },
    selectContainer: {
        width: '100%',
        textAlign: 'left',
    },
}))

export default function Options(props) {
    const { settings, scheduleTypeChanged } = props
    const classes = useStyles()
    const [scheduleType, setScheduleType] = useState('disabled')
    const [callType, setCallType] = useState(true)
    const [ignoreGroup, setIgnoreGroup] = useState(true)
    const [SendGreetingAccepted, setSendGreetingAccepted] = useState(false)
    const [SettingsTransfTicket, setSettingsTransfTicket] = useState(false)

    const { setSetting } = useSettings()

    useEffect(() => {
        if (!Object.keys(settings)?.length) return

        setScheduleType(settings.scheduleType)
        setCallType(settings.call)
        setIgnoreGroup(settings.ignoreGroup)
        setSendGreetingAccepted(settings.sendGreetingAccepted)
        setSettingsTransfTicket(settings.transferAdvice)
    }, [settings])

    async function handleScheduleType(value) {
        setScheduleType(value)
        await setSetting('scheduleType', value)

        toast.success('Operação atualizada com sucesso.')
        if (typeof scheduleTypeChanged === 'function') {
            scheduleTypeChanged(value)
        }
    }

    async function handleCallType(value) {
        setCallType(value)
        await setSetting('call', value)
        toast.success('Operação atualizada com sucesso.')
    }

    async function handleGroupType(value) {
        setIgnoreGroup(value)
        await setSetting('ignoreGroup', value)
        toast.success('Operação atualizada com sucesso.')
    }

    async function handleSendGreetingAccepted(value) {
        setSendGreetingAccepted(value)
        await setSetting('sendGreetingAccepted', value)
        toast.success('Operação atualizada com sucesso.')
    }

    async function handleSettingsTransfTicket(value) {
        setSettingsTransfTicket(value)
        await setSetting('transferAdvice', value)
        toast.success('Operação atualizada com sucesso.')
    }
    return (
        <>
            <Grid spacing={3} container>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="schedule-type-label">
                            Gerenciamento de Expediente
                        </InputLabel>
                        <Select
                            labelId="schedule-type-label"
                            value={scheduleType}
                            onChange={async e => {
                                handleScheduleType(e.target.value)
                            }}>
                            <MenuItem value={'disabled'}>Desabilitado</MenuItem>
                            <MenuItem value={'queue'}>Setores</MenuItem>
                            <MenuItem value={'company'}>Empresa</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="group-type-label">
                            Ignorar Mensagens de Grupos
                        </InputLabel>
                        <Select
                            labelId="group-type-label"
                            value={ignoreGroup}
                            onChange={async e => {
                                handleGroupType(e.target.value)
                            }}>
                            <MenuItem value={false}>Desativado</MenuItem>
                            <MenuItem value={true}>Ativado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="call-type-label">
                            Aceitar Chamada
                        </InputLabel>
                        <Select
                            labelId="call-type-label"
                            value={callType}
                            onChange={async e => {
                                handleCallType(e.target.value)
                            }}>
                            <MenuItem value={false}>Não Aceitar</MenuItem>
                            <MenuItem value={true}>Aceitar</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="sendGreetingAccepted-label">
                            Enviar saudação ao aceitar o ticket
                        </InputLabel>
                        <Select
                            labelId="sendGreetingAccepted-label"
                            value={SendGreetingAccepted}
                            onChange={async e => {
                                handleSendGreetingAccepted(e.target.value)
                            }}>
                            <MenuItem value={false}>Desabilitado</MenuItem>
                            <MenuItem value={true}>Habilitado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid xs={12} sm={6} md={4} item>
                    <FormControl className={classes.selectContainer}>
                        <InputLabel id="transferAdvice-label">
                            Enviar mensagem de transferencia de Setor/agente
                        </InputLabel>
                        <Select
                            labelId="transferAdvice-label"
                            value={SettingsTransfTicket}
                            onChange={async e => {
                                handleSettingsTransfTicket(e.target.value)
                            }}>
                            <MenuItem value={false}>Desabilitado</MenuItem>
                            <MenuItem value={true}>Habilitado</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    )
}
