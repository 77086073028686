const { makeStyles } = require('@material-ui/core')

const useStyle = ({ height = '12rem' }) =>
    makeStyles(theme => ({
        darkPaper: {
            backgroundColor: '#111b21',
        },
        lightPaper: {
            backgroundColor: '#fff',
        },
        elevation: {
            padding: '.5rem',
        },
        container: {
            gap: '1rem',
            width: '100%',
            margin: 'inherit',
        },
        containerUpload: {
            backgroundColor: '#fafafa',
            width: '16rem',
            border: '4px dashed #ccc',
            display: 'flex',
            height: height,
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            cursor: 'pointer',
        },
        containerImage: {
            position: 'relative',
        },
        modalImage: {
            width: '16rem',
            cursor: 'pointer',
            borderRadius: 8,
            height: height,
            objectFit: 'cover',
        },
        deleteButton: {
            position: 'absolute',
            padding: 4,
            right: 0,
            top: 0,
        },
        uniqueFile: {
            '& > div': {
                width: '100%',
            },
            '& .modalImage': {
                width: '100%',
            },
        },
        fitContent: {
            width: 'fit-content',
        },
        fullWidth: {
            width: '100%',
        },
    }))

export default useStyle
