import { Grid, TextField } from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, { useCallback, useEffect, useState } from 'react'

export function UnansweredFilter({ onChangeType, onChangeMinutes }) {
    const [open, setOpen] = useState(false)
    const [options, setOptions] = useState([])
    const [labelOptions, setLabelOptions] = useState([])
    const [minutes, setMinutes] = useState(null)
    const loading = open && options.length === 0

    useEffect(() => {
        let active = true
        if (!loading) return undefined

        if (active)
            setOptions([
                {id:1, desc:'Pelo cliente'},
                {id:2, desc:'Por mim'},
                {id:3, desc:'Pelo cliente em'},
                {id:4, desc:'Por mim em'},
            ])

        return () => (active = false)
    }, [loading])

    useEffect(() => {
        if (!open) setOptions([])
    }, [open])

    const handleChangeType = useCallback((e, value) => {
        setLabelOptions(value)
        if (!value) return onChangeType(null)

        return onChangeType(value)
    }, [onChangeType])

    const handleChangeMinutes = useCallback((e) => {
        let minutes = e?.target?.value;

        if(minutes < 0) minutes = 0;
        if(minutes.length > 10) minutes = minutes.slice(0, 10);

        setMinutes(minutes);
        if (!minutes) return onChangeMinutes(null)

        return onChangeMinutes(minutes)
    }, [onChangeMinutes])

    return (
        <Grid style={{ padding: '0px 10px 10px' }} container spacing={1}>
            <Grid item xs={labelOptions?.id > 2 ? 7 : 12}>            
                <Autocomplete
                    open={open}
                    onOpen={() => setOpen(true)}
                    onClose={() => setOpen(false)}
                    size="small"
                    options={options}
                    value={labelOptions?.id}
                    onChange={handleChangeType}
                    getOptionLabel={option => option.desc}
                    getOptionSelected={(option, value) => {
                        return (
                            option?.id === value ||
                            option?.id === value?.id
                        )
                    }}
                    renderInput={params => (
                        <TextField {...params} variant="outlined" label="Não Respondidas"/>
                    )}
                />
            </Grid>
            {(labelOptions?.id > 2) && (
                <Grid item xs={5}>
                    <TextField
                        label='Minutos'
                        size="small"
                        variant="outlined"
                        value={minutes}
                        InputProps={{
                            inputProps: {
                                type: 'number',
                                allowNegative: false,
                                min: 0, max: 9999999999,
                            },
                        }}
                        onChange={handleChangeMinutes}
                        fullWidth
                    />
                </Grid>
            )}
        </Grid>
    )
}
