


export default function Divider({ currentMessage, previousMessage }) {

    if (!previousMessage || currentMessage.fromMe === previousMessage.fromMe)
        return null

    return (
        <span key={`divider-${currentMessage.id}`} style={{ marginTop: 8 }} />
    )
}