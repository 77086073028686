const defaultInitial = {
    title: '',
}

const createLead = {
    ...defaultInitial,
    map: {
        name: 'contact_name',
        email: 'contact_email',
        phone: 'contact_number',
        origin_details: '',
        message: '',
        notes: '',
        ads_campaign: '',
    },
    default: {
        purpose: '',
        origin_details: '',
        notes: '',
        ads_campaign: '',
    },
}

const findPerson = {
    ...defaultInitial,
    type: 'tax_id'
}

export { createLead, findPerson }
