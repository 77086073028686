import {
    IconButton,
    makeStyles,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core'
import { Can } from 'components/Can'
import TableRowSkeleton from 'components/TableRowSkeleton'
import { Delete } from '@material-ui/icons'

const useStyles = makeStyles(theme => ({
    tableBody: {
        maxHeight: 500,
        overflow: 'auto',
    },
}))

const statusDesc = {
    pending: 'Pendente',
    sending: 'Enviando',
    sent: 'Enviado',
    failed: 'Erro',
}

const TransmissionListInside = ({ list, handleRemove, ...props }) => {
    const classes = useStyles()
    const loading = false

    return (
        <Table size="small">
            <TableHead>
                <TableRow>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Ações</TableCell>
                </TableRow>
            </TableHead>
            <TableBody className={classes.tableBody}>
                <>
                    {Boolean(list?.length) &&
                        list.map((item, index) => (
                            <TableRow key={item?.contact.id}>
                                <TableCell align="center">
                                    {item?.contact.name}
                                </TableCell>
                                <TableCell align="center">
                                    {statusDesc[item.status] || 'Pendente'}
                                </TableCell>
                                <TableCell align="center">
                                    <Can perform="transmission:update">
                                        <IconButton
                                            size="small"
                                            onClick={() => handleRemove(index)}>
                                            <Delete />
                                        </IconButton>
                                    </Can>
                                </TableCell>
                            </TableRow>
                        ))}
                    {loading && <TableRowSkeleton columns={3} />}
                </>
            </TableBody>
        </Table>
    )
}

export default TransmissionListInside
