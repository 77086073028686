import React, { useState, useEffect } from "react";

import { Avatar, CardHeader } from "@material-ui/core";

const TicketInfo = ({ contact, ticket, onClick }) => {
    const { user } = ticket
    const [userName, setUserName] = useState('')
    const [contactName, setContactName] = useState('')

    useEffect(() => {
        if (contact) {
            setContactName(contact.name)
            if (document.body.offsetWidth < 960) {
                if (contact.name.length > 10) {
                    const truncadName = contact.name.substring(0, 10) + '...'
                    setContactName(truncadName)
                }
            }
        }

        if (user && contact) {
            setUserName(
                `Atribuído à: ${user.name}`,
            )

            if (document.body.offsetWidth < 960) {
                setUserName(`${user.name}`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    return (
        <CardHeader
            onClick={onClick}
            style={{ cursor: "pointer" }}
            titleTypographyProps={{ noWrap: true }}
            subheaderTypographyProps={{ noWrap: true }}
            avatar={<Avatar src={contact.profilePicUrl} alt="contact_image" />}
            title={`${contactName} #${ticket.id}`}
            subheader={ticket.user && `${userName}`}
        />
    );
};

export default TicketInfo;
