const ptBr = {
    whatsappId: 'Conexão',
    name: 'Nome',
    number: 'Número',
    email: 'E-mail',
    phone: 'Telefone',
    flowId: 'ChatBot',
    delay: 'Delay',
    runAfter: 'Data de início',
    startTime: 'Horário de início',
    endTime: 'Horário de fim',
}

export default ptBr
