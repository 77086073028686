import MomentUtils from '@date-io/moment'
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    FormControlLabel,
    FormHelperText,
    Grid,
    InputLabel,
    makeStyles,
    MenuItem,
    Select,
    Switch,
    TextField,
    Tooltip,
    Typography,
} from '@material-ui/core'
import { Alert } from '@material-ui/lab'
import {
    KeyboardDateTimePicker,
    KeyboardTimePicker,
    MuiPickersUtilsProvider,
} from '@material-ui/pickers'
import FlowSelect from 'components/FlowSelect'
import { useFormik } from 'formik'
import moment from 'moment'
import TransmissionListInside from './TransmissionListInside'
import { useEffect, useState } from 'react'
import TransmissionListAdd from './TransmissionListAdd'
import { Add, Delete } from '@material-ui/icons'
import Validator from 'helpers/Validator'
import toastError from 'errors/toastError'
import api from 'services/api'
import ConnectionSelect from 'components/ConnectionSelect'

const useStyles = makeStyles(theme => ({
    gridRootSpacing: {
        '& > .MuiGrid-item': {
            padding: 12,
        },
    },

    sideContainer: {
        maxHeight: '100%',
        // overflow: 'auto',
        paddingInline: 3,

        '&::-webkit-scrollbar': {
            width: '0.4em',
        },
        '&::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
        },
    },

    filterButtonContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        marginTop: 15,
    },

    filterButton: {
        borderColor: theme.palette.apre.main,
        color: theme.palette.apre.main,
    },
}))

const TransmissionSchema = Validator.object().shape({
    name: Validator.string().required(),
    flowId: Validator.number().required(),
    whatsappId: Validator.number().required(),
    delay: Validator.string()
        .required()
        .oneOf(
            ['short', 'medium', 'long', 'giant'],
            'As opções válidas são: curto, médio, longo e muito longo',
        ),
    runAfter: Validator.string()
        .required()
        .test('dateValid', 'Data inválida', value => moment(value).isValid()),
    startTime: Validator.string()
        .required()
        .test('dateValid', 'Horário inválido', value =>
            moment(value, 'HH:mm:ss').isValid(),
        ),
    endTime: Validator.string()
        .required()
        .test('dateValid', 'Horário inválido', value =>
            moment(value, 'HH:mm:ss').isValid(),
        ),
})

const TransmissionModal = ({
    transmissionId,
    open,
    onClose,
    handleDelete,
    ...props
}) => {
    const classes = useStyles()
    const [transmissionList, setTransmissionList] = useState([])
    const [filterOpen, setFilterOpen] = useState(false)

    const formik = useFormik({
        initialValues: {
            name: '',
            flowId: '',
            whatsappId: '',
            delay: '',
            acceptOpenTicket: false,
            runAfter: moment().format('YYYY-MM-DD HH:mm:ss'),
            startTime: '00:00:00',
            endTime: '23:59:59',
        },
        onSubmit: values => {
            if (!transmissionList?.length)
                return toastError(
                    'Não foi adicionado nenhum contato para enviar a transmissão!',
                )

            handleSave({ ...values, transmissionList })
        },
        validationSchema: TransmissionSchema,
    })

    const reload = () => {
        if (transmissionId)
            api.get(`/transmission/${transmissionId}`, {
                include: { 'transmissionList.contact': '*' },
            }).then(response => {
                const { transmissionList, ...values } = response.data

                formik.setValues(values)
                setTransmissionList(transmissionList || [])
            })
    }

    useEffect(
        reload,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [transmissionId],
    )

    const handleSave = form => {
        if (transmissionId)
            api.put(`/transmission/${transmissionId}`, form).then(handleClose)
        else api.post(`/transmission`, form).then(handleClose)
    }

    const handleRemoveContact = contactIdx => {
        setTransmissionList(list => {
            list.splice(contactIdx, 1)
            return [...list]
        })
    }

    const handleDeleteAll = () => {
        // Fazer uma confirmação de remover

        setTransmissionList(list => [
            ...list.filter(item => item.status && item.status !== 'sent'),
        ])
    }

    const resetForm = () => {
        formik.resetForm()
        setTransmissionList([])
    }

    const handleClose = () => {
        resetForm()
        onClose()
    }

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
            <DialogContent>
                <MuiPickersUtilsProvider utils={MomentUtils}>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid
                            container
                            spacing={2}
                            classes={{
                                'spacing-xs-2': classes.gridRootSpacing,
                            }}>
                            <Grid item md={4} xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.sideContainer}>
                                    <Typography variant="subtitle1">
                                        Configurações da Transmissão
                                    </Typography>

                                    <Divider style={{ width: '100%' }} />

                                    <Grid item md={12} xs={6}>
                                        <TextField
                                            variant="outlined"
                                            fullWidth
                                            label="Nome"
                                            value={formik.values.name}
                                            name="name"
                                            onChange={formik.handleChange}
                                            error={Boolean(formik.errors?.name)}
                                            helperText={formik.errors?.name}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={6}>
                                        <ConnectionSelect
                                            value={formik.values.whatsappId}
                                            name="whatsappId"
                                            onChange={e =>
                                                formik.setFieldValue(
                                                    'whatsappId',
                                                    e,
                                                )
                                            }
                                            error={formik.errors?.whatsappId}
                                        />
                                    </Grid>
                                    <Grid item md={12} xs={6}>
                                        <FlowSelect
                                            value={formik.values.flowId}
                                            name="flowId"
                                            onChange={e =>
                                                formik.setFieldValue(
                                                    'flowId',
                                                    e,
                                                )
                                            }
                                            error={formik.errors?.flowId}
                                        />
                                    </Grid>

                                    <Alert severity="warning" icon={false}>
                                        Configure o delay para sua transmissão.
                                        Um delay maior reduz as chances de sua
                                        transmissão ser confundida com SPAM, mas
                                        pode aumentar o tempo total necessário
                                        para completá-la.
                                    </Alert>

                                    <Grid item xs={12}>
                                        <FormControl
                                            fullWidth
                                            error={Boolean(
                                                formik.errors?.delay,
                                            )}
                                            variant="outlined">
                                            <InputLabel id="delay-label">
                                                Delay entre mensagens
                                            </InputLabel>
                                            <Select
                                                label="Delay entre mensagens"
                                                value={formik.values.delay}
                                                labelId="delay-label"
                                                id="label"
                                                name="delay"
                                                onChange={formik.handleChange}>
                                                {/* <MenuItem value="mini">Muito curto 1-5s</MenuItem> */}
                                                <MenuItem value="short">
                                                    Curto 5-20s
                                                </MenuItem>
                                                <MenuItem value="medium">
                                                    Médio 20-50s
                                                </MenuItem>
                                                <MenuItem value="long">
                                                    Longo 50-120s
                                                </MenuItem>
                                                <MenuItem value="giant">
                                                    Muito longo 120-300s
                                                </MenuItem>
                                            </Select>

                                            {Boolean(formik.errors?.delay) && (
                                                <FormHelperText>
                                                    {formik.errors?.delay}
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    <Grid item md={12} xs={6}>
                                        <FormControlLabel
                                            label="Incluir tickets abertos"
                                            labelPlacement="start"
                                            control={
                                                <Switch
                                                    checked={
                                                        formik.values
                                                            .acceptOpenTicket
                                                    }
                                                    onChange={
                                                        formik.handleChange
                                                    }
                                                    name="acceptOpenTicket"
                                                    color="primary"
                                                />
                                            }
                                        />
                                    </Grid>

                                    <Grid item md={12} xs={6}>
                                        <KeyboardDateTimePicker
                                            ampm={false}
                                            inputVariant="outlined"
                                            fullWidth
                                            format="DD/MM/yyyy HH:mm"
                                            label="Iniciar envio"
                                            name="runAfter"
                                            value={formik.values.runAfter}
                                            onChange={e => {
                                                formik.setFieldValue(
                                                    'runAfter',
                                                    e?.format(
                                                        'YYYY-MM-DD HH:mm:ss',
                                                    ),
                                                )
                                            }}
                                            error={Boolean(
                                                formik.errors?.runAfter,
                                            )}
                                            helperText={formik.errors?.runAfter}
                                        />
                                    </Grid>

                                    <Tooltip title="Período do dia que a transmissão será enviada.">
                                        <Grid item xs={6}>
                                            <KeyboardTimePicker
                                                ampm={false}
                                                inputVariant="outlined"
                                                fullWidth
                                                format="HH:mm"
                                                label="Início"
                                                name="startTime"
                                                error={Boolean(
                                                    formik.errors?.startTime,
                                                )}
                                                helperText={
                                                    formik.errors?.startTime
                                                }
                                                value={moment(
                                                    formik.values.startTime,
                                                    'HH:mm:ss',
                                                ).toISOString()}
                                                onChange={e => {
                                                    formik.setFieldValue(
                                                        'startTime',
                                                        e?.format('HH:mm:ss'),
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Tooltip>

                                    <Tooltip title="Período do dia que a transmissão será enviada.">
                                        <Grid item xs={6}>
                                            <KeyboardTimePicker
                                                ampm={false}
                                                inputVariant="outlined"
                                                fullWidth
                                                format="HH:mm"
                                                label="Fim"
                                                name="endTime"
                                                error={Boolean(
                                                    formik.errors?.endTime,
                                                )}
                                                helperText={
                                                    formik.errors?.endTime
                                                }
                                                value={moment(
                                                    formik.values.endTime,
                                                    'HH:mm:ss',
                                                ).toISOString()}
                                                onChange={e => {
                                                    formik.setFieldValue(
                                                        'endTime',
                                                        e?.format('HH:mm:ss'),
                                                    )
                                                }}
                                            />
                                        </Grid>
                                    </Tooltip>
                                </Grid>
                            </Grid>

                            <Grid item md={8} xs={12}>
                                <Grid
                                    container
                                    spacing={2}
                                    className={classes.sideContainer}>
                                    <TransmissionListAdd
                                        setList={newList =>
                                            setTransmissionList(prev => [
                                                ...prev,
                                                ...newList,
                                            ])
                                        }
                                        open={filterOpen}
                                        onClose={() => setFilterOpen(false)}
                                    />

                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-between',
                                            width: '100%',
                                        }}>
                                        <Typography variant="subtitle1">
                                            Segmentação
                                        </Typography>

                                        {Boolean(transmissionList.length) && (
                                            <span>
                                                <Typography variant="caption">
                                                    {transmissionList.length}{' '}
                                                    contatos
                                                </Typography>

                                                {formik.values.status !==
                                                    'finished' && (
                                                    <>
                                                        <Button
                                                            size="small"
                                                            onClick={() =>
                                                                setFilterOpen(
                                                                    true,
                                                                )
                                                            }>
                                                            <Add />
                                                            Adicionar
                                                        </Button>

                                                        <Button
                                                            size="small"
                                                            onClick={
                                                                handleDeleteAll
                                                            }>
                                                            <Delete />
                                                            Remover todos
                                                        </Button>
                                                    </>
                                                )}
                                            </span>
                                        )}
                                    </div>

                                    <Divider style={{ width: '100%' }} />

                                    {Boolean(transmissionList?.length) ? (
                                        <TransmissionListInside
                                            list={transmissionList}
                                            handleRemove={handleRemoveContact}
                                        />
                                    ) : (
                                        <div
                                            className={
                                                classes.filterButtonContainer
                                            }>
                                            <Button
                                                disableElevation
                                                variant="outlined"
                                                classes={{
                                                    outlined:
                                                        classes.filterButton,
                                                }}
                                                onClick={() =>
                                                    setFilterOpen(true)
                                                }>
                                                Crie a lista de envios
                                            </Button>
                                        </div>
                                    )}
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                </MuiPickersUtilsProvider>
            </DialogContent>
            <DialogActions>
                {Boolean(handleDelete) && Boolean(transmissionId) && (
                    <Button onClick={handleDelete}>Deletar</Button>
                )}
                <Button onClick={formik.handleSubmit}>Salvar</Button>
            </DialogActions>
        </Dialog>
    )
}

export default TransmissionModal
